import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import {
  auth,
  fileDownload,
  layoutConfig,
  navbarConfig,
  notifications,
  s3Upload,
  settings,
  sidebarConfig,
  sidebarMenuConfig,
  school,
  program,
  classes,
  programInfo,
  instructor,
  student,
  location,
  sections,
  registrationForm,
  payoutAcc,
  products,
  emailConfig,
  webConfig,
  preEnrollForm,
  user,
  myAI,
  refunds,
  emailSubscription,
  blog,
} from './modules';

Vue.use(Vuex);

export const state = () => ({
  brand: 'Transition Enroll'
});

export const createStore = () => {
  return new Vuex.Store({
    state,
    getters: { settings: state => state.settings.state },
    mutations: {},
    actions: {},
    modules: {
      // Configuration
      settings,
      layoutConfig,
      sidebarConfig,
      sidebarMenuConfig,
      navbarConfig,

      // App
      auth,
      fileDownload,
      notifications,
      s3Upload,
      school,
      program,
      classes,
      programInfo,
      instructor,
      student,
      location,
      sections,
      registrationForm,
      payoutAcc,
      products,
      emailConfig,
      webConfig,
      preEnrollForm,
      user,
      myAI,
      refunds,
      emailSubscription,
      blog
    },
    plugins: [createPersistedState({ paths: ['auth'] })]
  });
};

const store = createStore();
export default store;
