import axios from 'axios';

const state = {};

const getters = {};

const mutations = {};

const actions = {
  async getAllTemplates(_obj, params) {
    const res = await axios.get(`api/marketing/`, { params });
    return res.data;
  },

  async getTemplate(_obj, id) {
    const res = await axios.get(`api/marketing/${id}/`);

    return res.data;
  },

  async createTemplate(_obj, data) {
    return axios.post(`api/marketing/`, data);
  },

  async updateTemplate(_obj, { id, data }) {
    return axios.patch(`api/marketing/${id}/`, data);
  },

  async deleteTemplate(_obj, id) {
    return axios.delete(`api/marketing/${id}/`);
  },

  async getTemplateImageUploadPresignedUrl(_obj, data) {
    return (await axios.post(`api/marketing/upload-url/`, data)).data.data;
  },

  async generateText(_obj, { id, data }) {
    return axios.post(`api/marketing/${id}/generate-content/`, data);
  },

  async generateImage(_obj, { id, data }) {
    return axios.post(`api/marketing/template-canvas/${id}/generate-image/`, data);
  },

  async getChatDetails() {
    const res = await axios.get(`api/marketing/chat-details/`);
    return res.data;
  },

  async updateChatDetails(_obj, { data }) {
    return axios.patch(`api/marketing/update-chat-details/`, data);
  },

  async getAllSchoolsContentTokens(_obj, { data }) {
    const res = await axios.post(`api/marketing/all-schools-content-tokens/`, data);
    return res.data;
  },

  async getSchoolContentTokens(_obj, params) {
    const res = await axios.get(`api/marketing/school-content-tokens/`, { params });
    return res.data;
  },

  async getCanvasImageUploadPresignedUrl(_obj, data) {
    return (await axios.post(`api/marketing/template-canvas/upload-url/`, data)).data.data;
  },

  async getTemplateCanvas(_obj, id) {
    const res = await axios.get(`api/marketing/template-canvas/${id}/`);

    return res.data;
  },

  async updateTemplateCanvas(_obj, { id, data }) {
    return axios.patch(`api/marketing/template-canvas/${id}/`, data);
  },

  async deleteTemplateCanvas(_obj, id) {
    return axios.delete(`api/marketing/template-canvas/${id}/`);
  },

  async createTemplateCanvas(_obj, data) {
    return axios.post(`api/marketing/template-canvas/`, data);
  }
};

export const myAI = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
