var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('sticky-layout',{attrs:{"body-class":_vm.bodyClass,"container-class":_vm.containerClass,"drawer-align":_vm.mainDrawerAlign,"sidebar-type":_vm.sidebarType,"sidebar-variant":_vm.sidebarVariant,"sidebar-search-form-class":"search-form--black","sidebar-menu":_vm.$store.getters['sidebarMenuConfig/layoutMenu'],"header-class":_vm.headerClass,"sub-layout":_vm.subLayout,"sub-layout-has-scrolling-region":_vm.subLayoutHasScrollingRegion,"sub-layout-drawer":_vm.subLayoutDrawer,"sub-layout-drawer-id":_vm.subLayoutDrawerId,"sub-layout-drawer-align":_vm.subLayoutDrawerAlign,"navbar-type":_vm.navbarType,"navbar-variant":_vm.navbarVariant,"navbar-container-class":_vm.navbarContainerClass,"navbar-brand":_vm.$store.state.brand,"navbar-full-sample-guest-mode":!_vm.isLoggedIn,"footer-brand":_vm.$store.state.brand},scopedSlots:_vm._u([{key:"navbar",fn:function(){return [_c('app-navbar',{attrs:{"data-primary":"","navbar-type":_vm.navbarType,"navbar-variant":_vm.navbarVariant,"navbar-brand":_vm.$store.state.brand,"navbar-container-class":_vm.navbarContainerClass,"navbar-toggle-class":"sidebar-toggle-menu"}},[_vm._t("navbar-content")],2)]},proxy:true},{key:"sidebar",fn:function(){return [_c('app-sidebar',{staticClass:"sidebar-p-t",attrs:{"sidebar-search":false,"sidebar-search-form-class":"search-form--black","sidebar-brand":false,"sidebar-type":_vm.sidebarType,"sidebar-variant":_vm.sidebarVariant,"sidebar-menu":_vm.$store.getters['sidebarMenuConfig/layoutMenu']},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var sidebar = ref.sidebar;
return [_vm._t("sidebar-content",null,{"sidebar":sidebar})]}}],null,true)})]},proxy:true},{key:"default",fn:function(ref){
var layoutProps = ref.layoutProps;
return [(
        _vm.get(_vm.getLoggedInUser, 'role_type') === _vm.USER_ROLE_TYPES.SCHOOL &&
          _vm.$route.name !== 'manage-payment' &&
          (_vm.getPayoutAccountStatus || _vm.isVerfifying)
      )?_c('b-skeleton-wrapper',{staticClass:"mb-3",attrs:{"loading":_vm.isVerfifying},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('div',{staticClass:"px-5"},[_c('b-skeleton',{staticClass:"w-100"})],1)]},proxy:true}],null,true)},[(_vm.getPayoutAccountStatus !== _vm.PAYOUT_ACC_STATUS.VERIFIED)?_c('div',[(_vm.getPayoutAccountStatus === _vm.PAYOUT_ACC_STATUS.LINKED)?_c('div',{staticClass:"alert alert-warning w-100 text-center",attrs:{"role":"alert"}},[_vm._v(" Your payout account is not yet verified by Stripe. "),_c('a',{staticStyle:{"text-decoration":"underline"},attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.verifyPayOutAcc.apply(null, arguments)}}},[_vm._v(" Confirm your verification now! ")])]):(_vm.getPayoutAccountStatus === _vm.PAYOUT_ACC_STATUS.UNLINKED)?_c('div',{staticClass:"alert alert-warning w-100 text-center",attrs:{"role":"alert"}},[_vm._v(" You haven't added any payment information. "),_c('router-link',{staticStyle:{"text-decoration":"underline"},attrs:{"to":{ name: 'manage-payment' }}},[_vm._v(" Connect an account now to start accepting payments ")])],1):_vm._e()]):_vm._e()]):_vm._e(),_c('router-view',{attrs:{"layout-props":layoutProps}})]}},{key:"footer-content",fn:function(footerProps){return [_c('p',{staticClass:"text-70 brand mb-24pt align-items-center"},[_c('strong',{staticClass:"text-70 small mr-2"},[_vm._v("POWERED BY")]),_c('img',{staticClass:"brand-icon",attrs:{"src":require("@/assets/images/logos/transition_enroll_tp.png"),"width":"140","alt":footerProps.footerBrand}})]),_c('p',{staticClass:"mb-8pt d-flex"},[_c('a',{staticClass:"text-70 text-underline mr-8pt small"},[_vm._v("Terms")]),_c('a',{staticClass:"text-70 text-underline small"},[_vm._v("Privacy")])]),_c('p',{staticClass:"text-50 measure-lead-max small"},[_vm._v(" Copyright "+_vm._s(footerProps.copyrightYear)+" © All rights reserved! ")])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }