import axios from 'axios';

const state = {};

const getters = {};

const mutations = {};

const actions = {
  async getAllUsers(_obj, params) {
    const res = await axios.get(`api/users/user-management/`, { params });
    return res.data;
  },

  async getUser(_obj, id) {
    const res = await axios.get(`api/users/user-management/${id}/`);
    return res.data;
  },
  async deleteUser(_obj, id) {
    const res = await axios.delete(`api/users/user-management/${id}/`);
    return res.data;
  },
  async createUser(_obj, data) {
    return axios.post(`api/users/user-management/`, data);
  },

  async updateUser(_obj, { id, data }) {
    return axios.patch(`api/users/user-management/${id}/`, data);
  },

  async updateActive(_obj, { id, data }) {
    return axios.patch(`api/users/user-management/${id}/update-account-status/`, data);
  },

  async getAllEntityUsers(_obj, params) {
    const res = await axios.get(`api/users/user-management/entity-user-list/`, { params });
    return res.data;
  },
  async getEntityUser(_obj, id) {
    const res = await axios.get(`api/users/user-management/${id}/entity-user-retreive/`);
    return res.data;
  },

  async createEntityUser(_obj, data) {
    return axios.post(`api/users/user-management/entity-create-user/`, data);
  },

  async updateEntityUser(_obj, { id, data }) {
    return axios.patch(`api/users/user-management/${id}/entity-user-partial-update/`, data);
  },
  async getUserUploadPresignedUrl(_obj, data) {
    return (await axios.post(`api/users/user-management/upload-url/`, data)).data.data;
  },

  async updateEntityActive(_obj, { id, data }) {
    return axios.patch(`api/users/user-management/${id}/change-entity-status/`, data);
  }
};

export const user = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
