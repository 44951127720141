<template>
  <b-navbar id="default-navbar" :class="navbarClass" :type="navbarType" :variant="navbarVariant">
    <div :class="navbarContainerClass">
      <!-- Toggle sidebar -->
      <button
        v-fmv-toggle.default-drawer
        class="navbar-toggler w-auto mr-16pt d-block rounded-0"
        :class="navbarToggleClass"
        type="button"
      >
        <md-icon>menu</md-icon>
      </button>

      <slot>
        <navbar-content
          :brand-image="getNavbarBrandLogo"
          :navbar-type="navbarType"
          :navbar-brand="getNavbarBrandText"
          :navbar-brand-class="navbarBrandClass"
          :navbar-search="navbarSearch"
          :navbar-full-sample-guest-mode="navbarFullSampleGuestMode"
          :navbar-content-container-class="navbarContentContainerClass || navbarContainerClass"
        />
      </slot>
    </div>
  </b-navbar>
</template>

<script>
import MdIcon from '@/components/Ui/MdIcon.vue';
import NavbarContent from './NavbarContent.vue';

import navbarProps from '@/components/Layout/navbar-props';
import navbarContentProps from '@/components/Layout/navbar-content-props';

import brandImage from '@/assets/images/logos/logo.png';
import { mapActions, mapGetters } from 'vuex';
import { USER_ROLE_TYPES } from '@/common/constants';

export default {
  components: {
    NavbarContent,
    MdIcon,
  },
  mixins: [navbarProps, navbarContentProps],
  props: {
    brandImage: {
      type: String,
      default() {
        return brandImage;
      },
    },
  },
  computed: {
    ...mapGetters('navbarConfig', ['getNavbarBrandLogo', 'getNavbarBrandText']),
    ...mapGetters('auth', ['isLoggedIn', 'getLoggedInUser']),
  },
  methods: {
    ...mapActions('navbarConfig', ['updateNavbarBrandDetails']),
    ...mapActions('school', ['getSchoolPaymentPlan']),
  },
  created() {
    if (this.isLoggedIn) {
      this.updateNavbarBrandDetails();
    }
  },
  async mounted() {
    if (this.isLoggedIn && this.getLoggedInUser.role_type === USER_ROLE_TYPES.SCHOOL) {
      await this.getSchoolPaymentPlan();
    }
  },
};
</script>
