import axios from 'axios';

const state = {};

const getters = {};

const mutations = {};

const actions = {
  async getAllRefundsReqs(_obj, params) {
    const res = await axios.get(`api/refunds/`, { params });
    return res.data;
  },

  async getRefundReq(_obj, id) {
    const res = await axios.get(`api/refunds/${id}/`);
    return res.data;
  },

  async createRefundReq(_obj, data) {
    return axios.post(`api/refunds/`, data);
  },

  async updateRefundReqStatus(_obj, { id, data }) {
    return axios.patch(`api/refunds/${id}/change-refund-request-status/`, data);
  },

  async cancelRefundReq(_obj, id) {
    return axios.get(`api/refunds/${id}/cancel-request/`);
  },

  async getAllRefundsReqsForStudent(_obj, params) {
    const res = await axios.get(`api/refunds/student-refund-requests/`, { params });
    return res.data;
  }
};

export const refunds = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
