<template>
  <sticky-layout
    :body-class="bodyClass"
    :container-class="containerClass"
    :drawer-align="mainDrawerAlign"
    :sidebar-type="sidebarType"
    :sidebar-variant="sidebarVariant"
    sidebar-search-form-class="search-form--black"
    :sidebar-menu="$store.getters['sidebarMenuConfig/layoutMenu']"
    :header-class="headerClass"
    :sub-layout="subLayout"
    :sub-layout-has-scrolling-region="subLayoutHasScrollingRegion"
    :sub-layout-drawer="subLayoutDrawer"
    :sub-layout-drawer-id="subLayoutDrawerId"
    :sub-layout-drawer-align="subLayoutDrawerAlign"
    :navbar-type="navbarType"
    :navbar-variant="navbarVariant"
    :navbar-container-class="navbarContainerClass"
    :navbar-brand="$store.state.brand"
    :navbar-full-sample-guest-mode="!isLoggedIn"
    :footer-brand="$store.state.brand"
  >
    <!-- Replace Navbar Component -->
    <template v-slot:navbar>
      <!-- <div class="navbar-height bg-white px-3">Replace Navbar Component</div> -->
      <app-navbar
        data-primary
        :navbar-type="navbarType"
        :navbar-variant="navbarVariant"
        :navbar-brand="$store.state.brand"
        :navbar-container-class="navbarContainerClass"
        navbar-toggle-class="sidebar-toggle-menu"
      >
        <slot name="navbar-content" />
      </app-navbar>
    </template>

    <!-- Replace Navbar Content -->
    <!-- <template v-slot:navbar-content> Replace Navbar Content </template> -->

    <!-- Replace Sidebar Component -->
    <template v-slot:sidebar>
      <app-sidebar
        :sidebar-search="false"
        sidebar-search-form-class="search-form--black"
        :sidebar-brand="false"
        :sidebar-type="sidebarType"
        :sidebar-variant="sidebarVariant"
        :sidebar-menu="$store.getters['sidebarMenuConfig/layoutMenu']"
        class="sidebar-p-t"
      >
        <template v-slot:default="{ sidebar }">
          <slot name="sidebar-content" v-bind:sidebar="sidebar" />
        </template>
      </app-sidebar>
    </template>

    <!-- Replace Sidebar Content -->
    <!-- <template v-slot:sidebar-content="{sidebar}">
      <div class="sidebar-heading">Replace Sidebar Content</div>
      <pre
        class="sidebar-text"
        v-text="sidebar.menu" />
    </template> -->

    <!-- Page Content -->

    <template v-slot:default="{ layoutProps }">
      <b-skeleton-wrapper
        :loading="isVerfifying"
        class="mb-3"
        v-if="
          get(getLoggedInUser, 'role_type') === USER_ROLE_TYPES.SCHOOL &&
            $route.name !== 'manage-payment' &&
            (getPayoutAccountStatus || isVerfifying)
        "
      >
        <template #loading>
          <div class="px-5">
            <b-skeleton class="w-100"></b-skeleton>
          </div>
        </template>
        <div v-if="getPayoutAccountStatus !== PAYOUT_ACC_STATUS.VERIFIED">
          <div
            class="alert alert-warning w-100 text-center"
            role="alert"
            v-if="getPayoutAccountStatus === PAYOUT_ACC_STATUS.LINKED"
          >
            Your payout account is not yet verified by Stripe.
            <a @click.prevent="verifyPayOutAcc" style="text-decoration: underline" href="#">
              Confirm your verification now!
            </a>
          </div>
          <div
            class="alert alert-warning w-100 text-center"
            role="alert"
            v-else-if="getPayoutAccountStatus === PAYOUT_ACC_STATUS.UNLINKED"
          >
            You haven't added any payment information.
            <router-link :to="{ name: 'manage-payment' }" style="text-decoration: underline">
              Connect an account now to start accepting payments
            </router-link>
          </div>
        </div>
      </b-skeleton-wrapper>
      <!-- <div class="px-5" v-if="!getPayoutAccountStatus">
        <div class="alert alert-danger w-100" role="alert">
          No Payout Information is Linked. Add Payout Information to offer programs.
        </div>
      </div> -->
      <router-view v-bind:layout-props="layoutProps" />
    </template>

    <!-- Footer Content -->
    <template v-slot:footer-content="footerProps">
      <p class="text-70 brand mb-24pt align-items-center">
        <strong class="text-70 small mr-2">POWERED BY</strong>
        <img
          class="brand-icon"
          src="@/assets/images/logos/transition_enroll_tp.png"
          width="140"
          :alt="footerProps.footerBrand"
        />
        <!-- {{ footerProps.footerBrand }} -->
      </p>

      <!-- <p class="text-50 measure-lead-max small">
        Healthcare Enroll connects healthcare employers with pre-qualified candidates from top CNA Schools.
      </p> -->

      <p class="mb-8pt d-flex">
        <a class="text-70 text-underline mr-8pt small">Terms</a>
        <a class="text-70 text-underline small">Privacy</a>
      </p>

      <p class="text-50 measure-lead-max small">
        Copyright {{ footerProps.copyrightYear }} &copy; All rights reserved!
      </p>
    </template>

    <!-- Footer Links -->
    <!-- <template v-slot:footer-links>
      <p class="mb-8pt d-flex">
        <a class="text-70 text-underline mr-8pt small">Terms</a>
        <a class="text-70 text-underline small">Privacy</a>
      </p>
    </template> -->

    <!-- Footer Copyright Text -->
    <!-- <template v-slot:footer-copyright-text>
      Copyright 2020 &copy; All rights reserved!
    </template> -->

    <!-- Footer Description Text -->
    <!-- <template v-slot:footer-description-text>
      Hello World!
    </template> -->

    <!-- Replace Footer Component -->
    <!-- <template v-slot:footer>
      Replace Footer Component
    </template> -->

    <!-- App Settings -->
    <!-- <template v-slot:after-layout>
      <no-ssr>
        <app-settings v-if="$store.state.settings.ui" />
      </no-ssr>
    </template> -->
  </sticky-layout>
</template>

<script>
import StickyLayout from '@/components/Layout/StickyLayout.vue';

// import AppSettings from '@/components/Settings';
import { mapGetters, mapActions } from 'vuex';
import mainDrawerMixin from '@/mixins/main-drawer';
import navbarConfigMixin from '@/mixins/navbar-config';
import sidebarConfigMixin from '@/mixins/sidebar-config';
import layoutConfigMixin from '@/mixins/layout-config';
import subLayoutMixin from '@/mixins/sub-layout';
import { PAYOUT_ACC_STATUS, USER_ROLE_TYPES } from '@/common/constants';
import AppNavbar from '@/components/Navbar/Navbar.vue';
import AppSidebar from '@/components/Sidebar/Sidebar.vue';
import { get } from 'lodash';

export default {
  components: {
    StickyLayout,
    AppNavbar,
    AppSidebar,
    // AppSettings,
  },
  mixins: [mainDrawerMixin, navbarConfigMixin, sidebarConfigMixin, layoutConfigMixin, subLayoutMixin],
  data() {
    return {
      headerClass: 'mb-0',
      PAYOUT_ACC_STATUS,
      isVerfifying: false,
      USER_ROLE_TYPES,
    };
  },
  computed: {
    ...mapGetters('payoutAcc', ['getPayoutAccountStatus']),
    ...mapGetters('auth', ['getLoggedInUser']),
    bodyClass() {
      return null;
    },
    sidebarConfigKey() {
      return this.mainDrawerSidebar;
    },
  },
  methods: {
    ...mapActions('payoutAcc', ['verifyAccount']),
    get,
    async verifyPayOutAcc() {
      this.isVerfifying = true;
      try {
        const resp = await this.verifyAccount();
        if (resp.status === PAYOUT_ACC_STATUS.VERIFIED) {
          this.makeToast({ variant: 'success', msg: 'Payout Info verified!' });
        } else this.makeToast({ variant: 'danger', msg: 'Cannot verify payout information. Please try again.' });
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.isVerfifying = false;
    },
  },
  created() {
    this.$root.$on('luma::headerClass', headerClass => {
      if (headerClass !== undefined) {
        this.headerClass = headerClass;
      }
    });
  },
};
</script>

<style lang="scss">
@media (min-width: 993px) {
  .sidebar-toggle-menu.d-block {
    display: none !important;
  }
}
</style>
