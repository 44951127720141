import store from '../store';
import { USER_ROLE_TYPES } from '../common/constants';

export async function paymentPlanGaurd(to, from, next) {
  if (store.getters['auth/getLoggedInUser'].role_type === USER_ROLE_TYPES.SCHOOL) {
    const paymentPlan = await store.dispatch('school/getSchoolPaymentPlan');

    if (to.meta.plansRequired && to.meta.plansRequired.includes(paymentPlan.payment_plan)) {
      next();
    } else {
      next({ name: 'school-dashboard' });
    }
  } else {
    next();
  }
}
