<template>
  <b-nav-item-dropdown
    variant="flush"
    no-caret
    right
    class="notification-scroll dropdown-notifications dropdown-xs-down-full d-sm-flex"
  >
    <template slot="button-content">
      <md-icon :class="iconClass">{{ icon }}</md-icon>
      <b-badge v-if="badge && unreadCount" pill variant="accent badge-notifications">
        {{ unreadCount }}
      </b-badge>
    </template>

    <div class="position-relative" style="height: 400px; overflow-y: auto">
      <div class="mt-3 ml-4 mr-3 d-flex justify-content-between">
        <h6>Notifications</h6>
        <a href="#" @click.prevent="markAllNotificationsRead">
          <md-icon :class="iconClass" v-b-popover.hover.top="'Mark All Read'">{{ markAllReadIcon }}</md-icon>
        </a>
      </div>

      <b-list-group flush>
        <notification-list-item
          v-for="(notification, index, id) in allNotifications"
          :key="index"
          :notification="notification"
          :class="!notification.is_read ? 'unread' : ''"
          :id="id"
          action
          @notificationRead="notificationReadAction"
        />

        <!-- <b-list-group-item>
          <b-btn
            variant="light"
            size="sm"
            :to="routeTo(routeMessages || routes.messages)"
            active-class="fake-active-class"
            exact-active-class="fake-exact-active-class"
            v-text="$t('view_all', { title: $t(title) })"
          />
        </b-list-group-item> -->
      </b-list-group>
      <infinite-loading v-if="totalNotifications !== 0" @infinite="infiniteLoadHandler">
        <div slot="spinner">
          <div class="text-center">
            <i class="fas fa-circle-notch fa-spin" />
          </div>
        </div>
        <div slot="no-more"></div>
        <div slot="no-results"></div>
      </infinite-loading>
      <p class="d-flex justify-content-center mt-5" v-if="!totalNotifications">No Notifications</p>
    </div>
  </b-nav-item-dropdown>
</template>

<i18n>
  {
    "en": {
      "view_all": "View {title}"
    },
    "ro": {
      "view_all": "Vezi {title}",
      "Messages": "Mesaje"
    }
  }
</i18n>

<script>
import InfiniteLoading from 'vue-infinite-loading';
import routeToMixin from '@/mixins/route-to';
import NotificationListItem from './NotificationListItem.vue';
import { mapActions, mapGetters } from 'vuex';
import { USER_ROLE_TYPES } from '../../common/constants';

export default {
  components: {
    NotificationListItem,
    InfiniteLoading,
  },
  mixins: [routeToMixin],
  props: {
    badge: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: 'notifications_none',
    },
    iconClass: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default() {
        return 'navbarMsgs.notifications';
      },
    },
    routeMessages: {
      type: [String, Object],
    },
  },
  data() {
    return {
      allNotifications: [],
      pageNum: 1,
      pageSize: 20,
      isLoadingMore: false,
      areNotificationsLoading: false,
      connection: null,
      unreadCount: 0,
      totalNotifications: 0,
      showNotification: false,
      markAllReadIcon: 'mail_outline',
    };
  },
  methods: {
    ...mapActions('notifications', ['getAllNotifications', 'updateNotification', 'markAllRead']),
    ...mapActions('fileDownload', ['downloadFile']),
    notificationReadAction(notification) {
      return {
        program_info_query: this.programInfoHandler,
        student_registration: this.studentRegistrtaionHandler,
        pre_enrollment_created: this.preEnrollmentHandler,
        app_feature_update: this.appFeatureUpdate,
        public_instructor_creation: this.publicInstructorCreationHandler,
        refund_requested: this.refundHandler,
        refund_approved: this.refundReviewHandler,
        refund_declined: this.refundReviewHandler,
        export_analytics: this.analyticsHandler,
        partnership_requested: this.npAffRequestHandler,
        partnership_accepted: this.npAffRequestHandler,
      }[notification.notification_type](notification);
    },
    async analyticsHandler(notification) {
      if (notification.extra.file_url) {
        await this.downloadGivenFile({ fileUrl: notification.extra.file_url, removeTimestamp: true });
      } else {
        this.makeToast({ variant: 'primary', msg: 'No student exist!' });
      }
    },
    async downloadGivenFile({ fileUrl, removeTimestamp = true }) {
      const h = this.$createElement;

      this.makeToast({
        variant: 'primary',
        msg: h('span', [h('i', { class: 'fas fa-download mr-2' }), 'Downloading File']),
        toaster: 'b-toaster-bottom-left',
      });
      try {
        await this.downloadFile({ fileUrl, removeTimestamp });

        this.makeToast({
          variant: 'success',
          msg: h('span', [h('i', { class: 'fas fa-download mr-2' }), 'File Downloaded']),
          toaster: 'b-toaster-bottom-left',
        });
      } catch (e) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
    },
    async studentRegistrtaionHandler(notification) {
      this.$router.push({ name: 'manage-registration-profile', params: { id: notification.extra.registration_id } });
    },
    async preEnrollmentHandler(notification) {
      this.$router.push({ name: 'schl-pre-enroll-student-profile', params: { id: notification.extra.pre_enroll_id } });
    },
    appFeatureUpdate(notification) {
      this.$router.push({ name: 'feature-update-details', params: { id: notification.extra.app_update_id } });
    },
    publicInstructorCreationHandler(notification) {
      this.$router.push({ name: 'edit-instructor', params: { id: notification.extra.instructor_id } });
    },

    refundHandler(notification) {
      this.$router.push({ name: 'sa-refund-details', params: { id: notification.extra.refund_id } });
    },
    refundReviewHandler(notification) {
      this.$router.push({ name: 'refund-details', params: { id: notification.extra.refund_id } });
    },
    async partnershipRequestHandler(notification) {
      if (notification.extra.request_from_type === USER_ROLE_TYPES.SCHOOL) {
        this.$router.push({ name: 'emp-school-profile', params: { id: notification.extra.request_from_id } });
      } else {
        this.$router.push({ name: 'schl-employer-profile', params: { id: notification.extra.request_from_id } });
      }
    },
    async testimonialRequestHandler(notification) {
      this.$router.push({ name: 'show-student-testimonial', params: { id: notification.object_id } });
    },
    async signUpRequestHandler(notification) {
      this.$router.push({ name: 'show-student-sign-up-request', params: { id: notification.object_id } });
    },
    async recurringFeeChangeHandler(notification) {
      this.$router.push({ name: 'emp-school-profile', params: { id: notification.object_id, detailModal: true } });
    },
    async npAffRequestHandler() {
      this.$router.push({ name: 'manage-affiliations-list' });
    },
    async programInfoHandler(notification) {
      this.$router.push({
        name: 'info-detail',
        // query: { id: notification.object_id },
        params: { data: notification.extra, id: notification.object_id },
      });
    },

    async markAllNotificationsRead() {
      const response = this.markAllRead();
      this.unreadCount = response.unread_count;
      for (const notification of this.allNotifications) {
        notification.is_read = true;
      }
    },
    async fetchAndSetNotifications(params = {}) {
      this.areNotificationsLoading = true;
      const response = await this.getAllNotifications({
        limit: this.pageSize,
        offset: (this.pageNum - 1) * this.pageSize,
        ...params,
      });
      if (response.data.results) {
        this.allNotifications.push(...response.data.results);
        this.totalNotifications = response.data.count;
      }
      this.unreadCount = response.data.unread_count;
      this.areNotificationsLoading = false;
    },

    async loadMore() {
      this.isLoadingMore = true;
      this.pageNum++;
      await this.fetchAndSetNotifications();
      this.isLoadingMore = false;
    },
    async infiniteLoadHandler($state) {
      if (this.allNotifications.length < this.totalNotifications) {
        await this.loadMore();
        $state.loaded();
      } else $state.complete();
    },
  },

  mounted() {
    this.fetchAndSetNotifications();
  },
  computed: {
    ...mapGetters('auth', ['getLoggedInUser', 'getAuthToken']),
  },
  created() {
    this.connection = new WebSocket(
      `${process.env.VUE_APP_BACKEND_WS_URL}/ws/notifications/${this.getLoggedInUser.id}/?token=${this.getAuthToken}`
    );
    this.connection.onmessage = event => {
      const data = JSON.parse(event.data).message;
      if (data.action === 'Send') {
        this.allNotifications.unshift(data.notification);
        this.unreadCount = data.unread_count;
        this.totalNotifications++;
        this.makeToast({ variant: 'info', msg: 'New Notification Received' });
      } else if (data.action === 'Update') {
        let notificationToUpate = this.allNotifications.find(notification => notification.id === data.notification.id);
        notificationToUpate.is_read = data.notification.is_read;
        this.unreadCount = data.unread_count;
      }
    };
    this.connection.onerror = () => {};
  },
  destroyed() {
    if (this.connection) this.connection.close();
  },
};
</script>

<style lang="scss">
.notification-scroll {
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  ::-webkit-scrollbar-track {
    background: var(--lightestgrey);
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
</style>
