import axios from 'axios';

const state = {};

const getters = {};

const mutations = {};

const actions = {
  async getAllBlogs(_obj, params) {
    const res = await axios.get(`api/blogs/`, { params });
    return res.data;
  },

  async getBlog(_obj, id) {
    const res = await axios.get(`api/blogs/${id}/`);
    return res.data;
  },
  async updateBlog(_obj, { id, data }) {
    return axios.patch(`api/blogs/${id}/`, data);
  },
  async createBlog(_obj, data) {
    const res = await axios.post(`api/blogs/`, data);
    return res.data;
  },
  async updateBlogStatus(_obj, { id, data }) {
    return axios.patch(`api/blogs/${id}/update-is-active/`, data);
  },

  async deleteBlog(_obj, id) {
    return axios.delete(`api/blogs/${id}/`);
  },
  async getBlogPresignedUrl(_obj, data) {
    return (await axios.post(`api/blogs/upload-url/`, data)).data.data;
  },
};

export const blog = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
