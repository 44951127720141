import store from '../store';
import { get } from 'lodash';

export function studentOnboardGuard(to, from, next) {
  // Redirecting students with incomplete profiles to their onboarding page.
  if (to.name !== 'student-onboarding') {
    if (!get(store.getters['student/getStudentProfile'], 'is_profile_complete')) {
      next({ name: 'student-onboarding' });
    } else {
      next();
    }
  } else if (get(store.getters['student/getStudentProfile'], 'is_profile_complete')) {
    next({ name: 'student-home' });
  } else {
    next();
  }
}
