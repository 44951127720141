import Vue from 'vue';

// import NoSsr from 'vue-no-ssr';

// Vue.component('NoSsr', NoSsr);

/**
 * Global Helpers
 */

import { mapGetters } from 'vuex';

Vue.mixin({
  computed: {
    ...mapGetters('auth', ['isLoggedIn']),
    ...mapGetters('settings', ['routes', 'layout'])
  },
  methods: {
    makeToast({
      variant = null,
      msg,
      title = undefined,
      noCloseButton = true,
      noHoverPause = true,
      toaster = undefined,
      autoHideDelay = 2500,
      noAutoHide = false
    }) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        noCloseButton: noCloseButton,
        autoHideDelay,
        noAutoHide: noAutoHide,
        noHoverPause: noHoverPause,
        ...(toaster && { toaster })
      });
    }
  }
});
