var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-avatar',{attrs:{"src":_vm.isLoading ? null : _vm.fileUrl || (_vm.user ? _vm.user[_vm.urlField] : null),"text":!_vm.hideText
      ? _vm.isLoading
        ? '...'
        : _vm.text ||
          (_vm.user && _vm.user[_vm.firstNameField] && _vm.user[_vm.lastNameField]
            ? ("" + (_vm.user[_vm.firstNameField][0]) + (_vm.user[_vm.lastNameField][0]))
            : '')
      : '',"rounded":_vm.rounded,"size":_vm.size,"variant":_vm.variant}})}
var staticRenderFns = []

export { render, staticRenderFns }