import axios from 'axios';

const state = {};

const getters = {};

const mutations = {};

const actions = {
  async getPreEnrolledStds(_obj, params) {
    const res = await axios.get(`api/pre-enrollments/`, { params });
    return res.data;
  },
  async getStudentPreEnrollProfile(_obj, params) {
    const res = await axios.get(`api/pre-enrollments/pre-enrolled-profile/`, { params });
    return res.data;
  },
  async getPreEnrollFormObj(_obj, id) {
    const res = await axios.get(`api/pre-enrollments/${id}/`);
    return res.data;
  },
  async createPreEnrollmentForm(_obj, data) {
    return axios.post(`api/pre-enrollments/`, data);
  },
  async resendPreEnrollEmail(_obj, id) {
    const res = await axios.get(`api/pre-enrollments/${id}/resend-pre-enrollment-email/`);
    return res.data;
  },
  async updatePreEnrollStatus(_obj, { id, data }) {
    return axios.patch(`api/pre-enrollments/${id}/update-status/`, data);
  },
  async updatePreenrollComment(_obj, { id, data }) {
    return axios.patch(`api/pre-enrollments/${id}/comment/`, data);
  },
  async getPublicPreEnrollmentForm(_obj, { params }) {
    const res = await axios.get(`api/pre-enrollments/public-static-fields/`, { params });
    return res.data;
  }
};

export const preEnrollForm = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
