import axios from 'axios';

const state = {};

const getters = {};

const mutations = {};

const actions = {
  async getRegistrationForm(_obj, { params }) {
    const res = await axios.get(`api/registration-form/`, { params });
    return res.data;
  },

  async createRegistrationForm(_obj, data) {
    return axios.post(`api/registration-form/`, data);
  },

  async updateRegistrationForm(_obj, { data }) {
    return axios.patch(`api/registration-form/update-static-fields/`, data);
  },
  async getPublicRegistrationForm(_obj, { params }) {
    const res = await axios.get(`api/registration-form/public-static-fields/`, { params });
    return res.data;
  },

  async createCustomQuestion(_obj, { data }) {
    return axios.post(`api/registration-form/custom-questions/`, data);
  },

  async updateCustomQuestion(_obj, { id, data }) {
    return axios.patch(`api/registration-form/custom-questions/${id}/`, data);
  },

  async getCustomQuestions(_obj, params) {
    const res = await axios.get(`api/registration-form/custom-questions/`, { params });
    return res.data;
  },

  async getQuestion(_obj, id) {
    const res = await axios.get(`api/registration-form/custom-questions/${id}/`);
    return res.data.data;
  },

  async deleteCustomQuestion(_obj, id) {
    return axios.delete(`api/registration-form/custom-questions/${id}/`);
  },

  async getApplicationCustomQuestions(_obj, params) {
    const res = await axios.get(`api/registration-form/tr-scholarship-sections/`, { params });
    return res.data;
  }
};

export const registrationForm = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
