<template>
  <div :class="`${navbarContentContainerClass} ${isMobileScreen ? 'justify-content-center' : ''}`">
    <b-navbar-nav
      v-if="
        (get(getLoggedInUser, 'role') === USER_ROLES.SUPER_ADMIN ||
          get(getLoggedInUser, 'role') === USER_ROLES.SCHOOL_ADMIN ||
          get(getLoggedInUser, 'role') === USER_ROLES.SCHOOL_OWNER) &&
          !isMediumScreen
      "
      class="flex-nowrap d-flex mr-2"
    >
      <b-nav-item-dropdown no-caret>
        <template slot="button-content">
          <md-icon>apps</md-icon>
        </template>

        <b-dropdown-header tag="div" class="form-label"> Switch to </b-dropdown-header>

        <b-dropdown-item :href="transitionMainUrl" target="_blank" exact>
          <md-icon>open_in_new</md-icon>
          Transition Connect
        </b-dropdown-item>
        <!-- 
        <b-dropdown-item
          v-if="get(getLoggedInUser, 'linked_entity.transition_sub')"
          :href="
            get(getLoggedInUser, 'role') !== USER_ROLES.SUPER_ADMIN
              ? get(getLoggedInUser, 'linked_entity.transition_sub')
              : ''
          "
          target="_blank"
          exact
        >
          <md-icon>open_in_new</md-icon>
          {{ 'Career Portal' }}
        </b-dropdown-item> -->
      </b-nav-item-dropdown>
    </b-navbar-nav>

    <!-- Brand -->
    <b-navbar-brand
      :to="isRoutePublicSchlLanding ? { name: 'sch-public-home' } : routeTo(routes.home)"
      :class="navbarBrandClass"
    >
      <!-- <fmv-avatar :title="true" size="sm" no-link class="navbar-brand-icon mr-0 mr-lg-8pt">
        <<b-img :src="brandImage" class="img-fluid" width="40" :alt="navbarBrand" />
        <b-img src="@/assets/images/logos/logo_icon.png" class="img-fluid" width="10" :alt="navbarBrand" />
      </fmv-avatar> -->
      <b-img
        :src="brandImage || defBrandImage"
        :height="brandImage ? 60 : 40"
        style="object-fit: contain"
        :style="isMobileScreen ? 'max-width: 100px;' : 'max-width: 130px;'"
        :alt="navbarBrand"
      />

      <!-- <span class="d-none d-lg-block" v-text="navbarBrand" v-if="brandImage" /> -->
    </b-navbar-brand>

    <!-- <template v-if="!navbarFullSampleGuestMode && navbarStats && !isInstructor">
      <span class="d-none d-md-flex align-items-center mr-16pt">
        <fmv-avatar :title="true" title-class="navbar-avatar" size="sm" class="mr-12pt">
          <md-icon>opacity</md-icon>
        </fmv-avatar>
        <small class="flex d-flex flex-column">
          <strong class="navbar-text-100">Experience IQ</strong>
          <span class="navbar-text-50">2,300 points</span>
        </small>
      </span>
    </template> -->

    <!-- <  template v-if="!navbarFullSampleGuestMode && navbarStats && isInstructor">
      <span class="d-none d-md-flex align-items-center mr-16pt">
        <fmv-avatar :title="true" title-class="navbar-avatar" size="sm" class="mr-12pt">
          <md-icon>trending_up</md-icon>
        </fmv-avatar>
        <small class="flex d-flex flex-column">
          <strong class="navbar-text-100" v-text="$t('Earnings')" />
          <span class="navbar-text-50">&dollar;12.3k</span>
        </small>
      </span>
      <span class="d-none d-md-flex align-items-center mr-16pt">
        <fmv-avatar :title="true" title-class="navbar-avatar" size="sm" class="mr-12pt">
          <md-icon>receipt</md-icon>
        </fmv-avatar>
        <small class="flex d-flex flex-column">
          <strong class="navbar-text-100" v-text="$t('Sales')" />
          <span class="navbar-text-50">264</span>
        </small>
      </span>
    </> -->
    <template v-if="isRoutePublicSchlLanding">
      <b-navbar-nav class="d-none d-sm-flex flex justify-content-end mr-5">
        <b-nav-item-dropdown right v-if="get(getCurrentSchool, 'custom_navbars.length')">
          <template slot="button-content">Menu</template>
          <div v-for="item in get(getCurrentSchool, 'custom_navbars')" :key="item.id">
            <b-dropdown-item :href="getClickableLink(item.link)" target="_blank">
              <md-icon class="ml-0 mr-2">{{ item.icon }}</md-icon>
              {{ item.text }}
            </b-dropdown-item>
          </div>
        </b-nav-item-dropdown>
      </b-navbar-nav>

      <!-- School's website link for public pages. (use if needed) -->
      <!-- <b-navbar-nav class="d-none d-sm-flex flex justify-content-end mr-5">
        <li class="nav-item" v-if="get(getCurrentSchool, 'website_url')">
          <a :href="getClickableLink(get(getCurrentSchool, 'website_url'))" target="_blank" class="nav-link">Home</a>
        </li> -->

      <!-- School's public pages. -->
      <!-- <b-nav-item :to="{ name: 'public-sch-programs' }" exact>Programs</b-nav-item> -->
      <!-- </b-navbar-nav> -->
    </template>

    <!-- Navbar routes for student layout. -->
    <template v-else-if="get(getLoggedInUser, 'role') === USER_ROLES.STUDENT">
      <!-- TODO: Show these routes to unauthenticated user. -->

      <b-navbar-nav class="d-none d-sm-flex flex justify-content-end mr-5">
        <b-nav-item-dropdown right>
          <template slot="button-content"> Jobs </template>

          <b-dropdown-item :to="{ name: 'student-jobs-listing' }" exact>All Jobs </b-dropdown-item>
          <b-dropdown-item :to="{ name: 'student-local-jobs-listing' }" exact>Local Jobs </b-dropdown-item>
        </b-nav-item-dropdown>
        <b-nav-item :to="{ name: 'student-employers-list' }">Employers</b-nav-item>
        <b-nav-item :to="{ name: 'std-manage-resume' }">Resume</b-nav-item>
        <!-- TODO: Uncomment after interviews integration. -->
        <!-- <b-nav-item :to="{ name: 'std-manage-resume' }">Interviews</b-nav-item> -->

        <b-nav-item :to="{ name: 'student-careers' }" exact>Careers</b-nav-item>
        <b-nav-item :to="{ name: 'student-events' }">Events</b-nav-item>
        <!-- <b-nav-item :to="{ name: 'student-home' }" exact v-b-tooltip.hover.bottom="'Coming Soon!'" disabled
          >Events</b-nav-item
        > -->
        <!-- Sample Dropdown style links -->
        <!-- <b-nav-item-dropdown :text="$t('Courses')" no-caret>
          <b-dropdown-item :to="routeTo(routes.studentCourses)">{{ $t('Browse Courses') }}</b-dropdown-item>
          <b-dropdown-item :to="routeTo(routes.studentTakeCourse)">
            <span class="mr-16pt">{{ $t('Take Course') }}</span>
            <b-badge variant="accent badge-notifications" class="text-uppercase ml-auto" v-text="$t('Pro')" />
          </b-dropdown-item>
          <b-dropdown-item :to="routeTo(routes.studentTakeLesson)">{{ $t('Take Lesson') }}</b-dropdown-item>
        </b-nav-item-dropdown> -->
      </b-navbar-nav>
    </template>

    <template v-if="navbarSearch">
      <!-- Search -->
      <b-form class="search-form navbar-search d-none mr-16pt d-md-flex">
        <!-- :class="{
          'd-lg-flex': navbarFullSampleGuestMode,
          'd-md-flex': !navbarFullSampleGuestMode,
        }"
      > -->
        <b-button variant="none">
          <md-icon>search</md-icon>
        </b-button>
        <b-form-input placeholder="Search" type="text" />
      </b-form>
      <!-- // END Search -->
    </template>

    <!-- Menu -->
    <b-navbar-nav class="flex-nowrap d-flex ml-auto" v-if="!isRoutePublicSchlLanding">
      <!-- <template v-if="isRoutePublicSchlLanding">
        <li class="nav-item">
          <b-btn :to="{ name: 'login' }" :variant="ctaClass"><md-icon class="mr-1">lock_open</md-icon> Login</b-btn>
        </li>
      </template> -->

      <template>
        <!-- Messages -->
        <!-- <navbar-notifications
          icon="mail_outline"
          icon-class="icon-24pt"
          title="Messages"
          :notifications="messages"
          :route-messages="routes.messages"
        /> -->

        <!-- Notifications -->
        <navbar-notifications
          :badge="true"
          title="navbarMsgs.notifications"
          :route-messages="routes.messages"
          v-if="
            [USER_ROLE_TYPES.SCHOOL, USER_ROLE_TYPES.EMPLOYER, USER_ROLE_TYPES.SUPER_ADMIN].includes(
              get(getLoggedInUser, 'role_type')
            )
          "
        />

        <b-nav-item-dropdown
          right
          no-caret
          v-if="
            get(getLoggedInUser, 'role') === USER_ROLES.SCHOOL_OWNER ||
              get(getLoggedInUser, 'role') === USER_ROLES.SCHOOL_ADMIN
          "
        >
          <template slot="button-content">
            <md-icon>settings</md-icon>
          </template>
          <b-dropdown-header tag="div" class="form-label"> Settings </b-dropdown-header>

          <b-dropdown-item
            :to="{ name: 'manage-info-list' }"
            v-if="
              get(getLoggedInUser, 'role') === USER_ROLES.SCHOOL_OWNER ||
                get(getLoggedInUser, 'role') === USER_ROLES.SCHOOL_ADMIN
            "
          >
            <md-icon>contact_mail</md-icon> Program Inquiries
          </b-dropdown-item>

          <b-dropdown-item
            :to="{ name: 'school-setup-guide' }"
            v-if="
              get(getLoggedInUser, 'role') === USER_ROLES.SCHOOL_OWNER ||
                get(getLoggedInUser, 'role') === USER_ROLES.SCHOOL_ADMIN
            "
          >
            <md-icon>build</md-icon> Setup Guide
          </b-dropdown-item>

          <b-dropdown-item
            :to="{ name: 'manage-users' }"
            v-if="get(getLoggedInUser, 'role') === USER_ROLES.SCHOOL_OWNER"
          >
            <md-icon>person</md-icon> Users
          </b-dropdown-item>

          <b-dropdown-divider class="my-1" />

          <b-dropdown-item
            v-if="[USER_ROLES.SCHOOL_OWNER, USER_ROLES.SCHOOL_ADMIN].includes(get(getLoggedInUser, 'role'))"
            :to="{ name: 'feature-updates-page' }"
          >
            <md-icon>lightbulb</md-icon> Feature Updates
          </b-dropdown-item>

          <template
            v-if="
              (get(getLoggedInUser, 'role') === USER_ROLES.SUPER_ADMIN ||
                get(getLoggedInUser, 'role') === USER_ROLES.SCHOOL_ADMIN ||
                get(getLoggedInUser, 'role') === USER_ROLES.SCHOOL_OWNER) &&
                isMediumScreen
            "
          >
            <b-dropdown-divider class="mb-1" />

            <b-dropdown-item :href="transitionMainUrl" target="_blank" exact>
              <md-icon>open_in_new</md-icon>
              Transition Connect
            </b-dropdown-item>

            <!-- <b-dropdown-item
              v-if="get(getLoggedInUser, 'linked_entity.transition_sub')"
              :href="
                get(getLoggedInUser, 'role') !== USER_ROLES.SUPER_ADMIN
                  ? get(getLoggedInUser, 'linked_entity.transition_sub')
                  : ''
              "
              target="_blank"
              exact
            >
              <md-icon>open_in_new</md-icon>
              {{ 'Career Portal' }}
            </b-dropdown-item> -->
          </template>
        </b-nav-item-dropdown>

        <!-- Localization -->
        <!-- <i18n-dropdown navbar :locales="localesOptions" /> -->

        <!-- User Dropdown -->
        <b-nav-item-dropdown right>
          <template slot="button-content">
            <user-avatar :user="getLoggedInUser" variant="dark"> </user-avatar>
          </template>
          <b-dropdown-header tag="div" class="form-label">
            {{ $t('navbarMsgs.manage') }}
          </b-dropdown-header>

          <b-dropdown-item
            :to="{ name: 'student-my-profile' }"
            v-if="get(getLoggedInUser, 'role') === USER_ROLES.STUDENT"
          >
            <md-icon>person</md-icon> {{ $t('profileMsgs.myProfile') }}
          </b-dropdown-item>
          <b-dropdown-item
            v-else-if="get(getLoggedInUser, 'role') !== USER_ROLES.SCHOOL_ADMIN"
            :to="{ name: 'my-profile' }"
          >
            <md-icon>person</md-icon> Account
          </b-dropdown-item>

          <b-dropdown-item :to="{ name: 'change-password' }"> <md-icon>security</md-icon> Security </b-dropdown-item>

          <!-- Employer admin's management option -->
          <!-- <b-dropdown-divider v-if="get(getLoggedInUser, 'role') === USER_ROLES.EMPLOYER_ADMIN" />

          <b-dropdown-item
            :to="{ name: 'manage-company-profile' }"
            v-if="get(getLoggedInUser, 'role') === USER_ROLES.EMPLOYER_ADMIN"
          >
            <md-icon>business</md-icon> Company Profile
          </b-dropdown-item> -->

          <!-- School owner & admin's management option -->
          <!-- <b-dropdown-divider
            v-if="
              get(getLoggedInUser, 'role') === USER_ROLES.SCHOOL_OWNER ||
              get(getLoggedInUser, 'role') === USER_ROLES.SCHOOL_ADMIN
            "
          /> -->

          <!-- <b-dropdown-item
            :to="{ name: 'manage-school-profile' }"
            v-if="
              get(getLoggedInUser, 'role') === USER_ROLES.SCHOOL_OWNER ||
              get(getLoggedInUser, 'role') === USER_ROLES.SCHOOL_ADMIN
            "
          >
            <md-icon>business</md-icon> School Profile
          </b-dropdown-item> -->

          <!-- <b-dropdown-item
            :to="{ name: 'manage-questions' }"
            v-if="
              get(getLoggedInUser, 'role') === USER_ROLES.SCHOOL_OWNER ||
              get(getLoggedInUser, 'role') === USER_ROLES.SCHOOL_ADMIN
            "
          >
            <md-icon>question_answer</md-icon> Custom Questions
          </b-dropdown-item> -->

          <b-dropdown-divider />

          <b-dropdown-item :to="routeTo(routes.logout || routes.login)">
            <md-icon>lock</md-icon> {{ $t('navbarMsgs.logout') }}
          </b-dropdown-item>
        </b-nav-item-dropdown>
        <!-- // END User dropdown -->
      </template>
    </b-navbar-nav>
    <!-- // END Menu -->
  </div>
</template>

<script>
import routeToMixin from '@/mixins/route-to';
import MdIcon from '@/components/Ui/MdIcon.vue';
import { mapGetters } from 'vuex';
import NavbarNotifications from './Notifications.vue';
import UserAvatar from '../User/UserAvatar.vue';
import { USER_ROLES, USER_ROLE_TYPES } from '../../common/constants';
import defBrandImage from '@/assets/images/logos/transition_enroll_tp.png';
import { get } from 'lodash';

export default {
  components: {
    NavbarNotifications,
    MdIcon,
    UserAvatar,
  },
  mixins: [routeToMixin],
  props: {
    navbarType: {
      type: String,
      default: () => 'light',
    },
    navbarContentContainerClass: {
      type: [String, Array, Object],
      default: () => 'd-flex w-100 align-items-center',
    },
    navbarFullSampleGuestMode: {
      type: Boolean,
    },
    navbarBrand: {
      type: String,
      default: () => 'Transition Enroll',
    },
    brandImage: {
      type: String,
      default() {
        return defBrandImage;
      },
    },
    navbarBrandClass: {
      type: [String, Object, Array],
      default: () => null,
    },
    navbarSearch: {
      type: Boolean,
      default: () => true,
    },
    navbarStats: {
      type: Boolean,
      default: () => true,
    },
  },
  data() {
    return {
      USER_ROLES,
      USER_ROLE_TYPES,
      defBrandImage,
      windowWidth: window.innerWidth,
      localesOptions: [{ code: 'en', iso: 'en-US', file: 'en.json' }],
      isInstructor: null,
      messages: [
        {
          id: 1,
          created_at: this.$t('diff_for_humans', {
            when: this.$tc('datetime_units.min', 3, { value: 3 }),
          }),
          iconClass: 'text-danger',
          avatar: '/images/people/110/woman-5.jpg',
          name: 'Michelle',
          message: 'Clients loved the new design.',
          is_read: true,
        },
        {
          id: 2,
          created_at: this.$t('diff_for_humans', {
            when: this.$tc('datetime_units.hr', 5, { value: 5 }),
          }),
          iconClass: 'text-success',
          avatar: '/images/people/110/woman-5.jpg',
          name: 'Michelle',
          message: '🔥 Superb job..',
          is_read: false,
        },
      ],
      transitionMainUrl: process.env.VUE_APP_TRANSITION_MAIN_URL,
    };
  },
  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    ...mapGetters('school', ['getCurrentSchool']),
    isNavbarDark() {
      let some = [this.navbarType.indexOf('dark') !== -1, this.navbarType.indexOf('black') !== -1];
      return some.some(some => !!some);
    },
    ctaClass() {
      return this.isNavbarDark ? 'white' : 'secondary';
    },

    isRoutePublicSchlLanding() {
      return ['sch-public-home', 'public-sch-programs', 'program-detail', 'public-sponsorships'].includes(
        this.$route.name
      );
    },
    isMobileScreen() {
      return this.windowWidth <= 420;
    },
    isMediumScreen() {
      return this.windowWidth <= 992;
    },
  },
  methods: {
    get,
    setIsInstructor() {
      let path = '';
      try {
        path = window ? window.location.pathname : this.$route.path;
      } catch (e) {
        // Handle if needed
      }
      this.isInstructor = path.indexOf('instructor') !== -1;
    },
    getClickableLink(link) {
      return link.startsWith('http://') || link.startsWith('https://') ? link : `https://${link}`;
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
  },
  watch: {
    '$route.path': {
      immediate: true,
      handler: 'setIsInstructor',
    },
  },
  beforeMount() {
    document.addEventListener('inertia:success', () => this.setIsInstructor());
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  destroyed() {
    document.removeEventListener('inertia:success', () => this.setIsInstructor());
  },
};
</script>
