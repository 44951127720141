import axios from 'axios';

const state = {};

const getters = {};

const mutations = {};

const actions = {
  async getAllProgramInfos(_obj, params) {
    const res = await axios.get(`api/contact_program_info/`, { params });
    return res.data;
  },

  async getProgramInfo(_obj, id) {
    const res = await axios.get(`api/contact_program_info/${id}/`);
    return res.data.data;
  },

  async createProgramInfo(_obj, { data, config }) {
    return axios.post(`api/contact_program_info/`, data, config);
  },

  async replyProgramInfo(_obj, { id, data }) {
    return axios.patch(`api/contact_program_info/${id}/reply-to-query`, data);
  },

  async deleteProgramInfo(_obj, id) {
    return axios.delete(`api/contact_program_info/${id}/`);
  },
  async getInquiriesCsvUrl() {
    const profile = (await axios.get(`api/contact_program_info/export-prg-inqueries-csv/`)).data;
    return profile;
  }
};

export const programInfo = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
