import axios from 'axios';
import { get } from 'lodash';

const state = {
  currentSchool: null,
  aiCallLogs: false,
  schoolPaymentPlan: null,
  schoolsEmpFilters: {
    zipCode: null,
    zipCodeRange: 30,
  },
  isFilterLoading: false,
};

const getters = {
  getCurrentSchool: state => state.currentSchool,
  getSchoolPaymentPlan: state => state.schoolPaymentPlan,
  getSchoolsEmpFilters: state => state.schoolsEmpFilters,
  getIsFilterLoading: state => state.isFilterLoading,
  getAiCallLogsStatus: state => state.aiCallLogs,
};

const mutations = {
  updateCurrentSchool: (state, school) => {
    state.currentSchool = school;
  },
  updateAiCallLogs: (state, aiCallLogs) => {
    state.aiCallLogs = aiCallLogs;
  },
  updateSchoolPaymentPlan: (state, isSchoolAdvanced) => {
    state.schoolPaymentPlan = isSchoolAdvanced;
  },
  updateSchoolsEmpFilters: (state, updatedFilters) => {
    state.schoolsEmpFilters = { ...state.schoolsEmpFilters, ...updatedFilters };
  },
  updateIsFilterLoading: (state, status) => {
    state.isFilterLoading = status;
  },
};

const actions = {
  async getAllSchools(_obj, params) {
    const res = await axios.get(`api/schools/`, { params });
    return res.data;
  },
  async getAllTransPrograms(_obj, params) {
    const res = await axios.get(`api/programs/all-programs-from-transition/`, { params });

    return res.data;
  },

  async getSchoolTransPrograms(_obj, params) {
    const res = await axios.get(`api/programs/school-offered-programs-from-transition/`, { params });

    return res.data;
  },

  async getSchool(_obj, id) {
    const res = await axios.get(`api/schools/${id}/`);
    return res.data;
  },
  async getAiCallLogs({ commit }, params) {
    commit('updateAiCallLogs', null);
    const res = await axios.get(`api/schools/ai-call-logs-toggle/`, { params });
    commit('updateAiCallLogs', res.data.data);
    return res.data.data;
  },
  async getSchoolsCallLogs(_obj, params) {
    const res = await axios.get(`api/schools/ai-call-logs/`, { params });
    return res.data;
  },

  async getSchoolAiCallLog(_obj,params) {
    const res = await axios.get(`api/schools/ai-call-logs-retrieve/`, { params });
    return res.data;
  },

  async createSchool(_obj, data) {
    return axios.post(`api/schools/`, data);
  },

  async getSchoolPaymentPlans(_obj, params) {
    const res = await axios.get(`api/schools/payment-plans/`, { params });
    return res.data;
  },

  async updateSchool(_obj, { id, data }) {
    return axios.patch(`api/schools/${id}/`, data);
  },

  async createInterestedSchoolReview(_obj, { id, data }) {
    return axios.patch(`api/schools/interested-schools/${id}/review-on-school/`, data);
  },

  async deleteSchool(_obj, id) {
    return axios.delete(`api/schools/${id}/`);
  },

  async getSchoolImageUploadPresignedUrl(_obj, data) {
    return (await axios.post(`api/schools/image-upload-url/`, data)).data.data;
  },

  async getSchoolStudentCounts(_obj, queryData) {
    return (await axios.post(`api/schools/get-students-count/`, queryData)).data.data;
  },

  async getSchoolFromCurrentDomain({ commit, state }, { storeSchool = false }) {
    if (state.currentSchool) {
      return state.currentSchool;
    }
    if (window.location.host !== process.env.VUE_APP_MAIN_DOMAIN) {
      const res = await axios.get(`api/schools/get-domain-school/`, { params: { url: window.location.host } });

      if (storeSchool) {
        commit('updateCurrentSchool', res.data.data);
      }
      return res.data.data;
    }
    return null;
  },

  async getSchoolPaymentPlan({ commit, state }) {
    if (state.schoolPaymentPlan) {
      return state.schoolPaymentPlan;
    } else {
      const res = await axios.get(`api/schools/payment-plan-status/`);
      commit('updateSchoolPaymentPlan', res.data.data);
      return res.data.data;
    }
  },

  async getSchoolFromDomain({ commit }, params) {
    const res = await axios.get(`api/schools/get-domain-school/`, { params });
    commit('updateCurrentSchool', res.data.data);
    return res.data;
  },

  async getLocalJobsForCurrentSchool(_obj, params) {
    const res = await axios.get(`api/jobs/get-local-jobs/`, { params });
    return res.data;
  },
  async updateMySchoolProfile({ dispatch }, data) {
    const resp = await axios.patch(`api/schools/update-school-profile/`, data);
    if (get(resp.data, 'data')) {
      dispatch('auth/updateLoggedInUserEntity', resp.data.data, { root: true });
    }
    return resp;
  },

  async getSchoolSetupGuideStatus(_obj, params) {
    const res = await axios.get(`api/schools/setup-guide-statuses/`, { params });
    return res.data;
  },
  async getAllInterestedSchools(_obj, params) {
    const res = await axios.get(`api/schools/interested-schools/`, { params });
    return res.data;
  },
  async getInterestedSchool(_obj, id) {
    const res = await axios.get(`api/schools/interested-schools/${id}/`);
    return res.data;
  },

  async createInterestedSchool(_obj, data) {
    return axios.post(`api/schools/interested-schools/`, data);
  },

  async updateInterestedSchool(_obj, { id, data }) {
    return axios.patch(`api/schools/interested-schools/${id}/`, data);
  },

  async updateMetaTags(_obj, { id, data }) {
    return axios.patch(`api/schools/${id}/update-meta-tags/`, data);
  },

  async createCustomNavbarItem(_obj, data) {
    return axios.post(`api/schools/custom-navbar-menu/`, data);
  },

  async getAllCustomNavbarItem(_obj, params) {
    const res = await axios.get(`api/schools/custom-navbar-menu/`, { params });
    return res.data;
  },

  async getCustomNavbarItem(_obj, id) {
    const res = await axios.get(`api/schools/custom-navbar-menu/${id}/`);
    return res.data;
  },

  async updateCustomNavbarItem(_obj, { id, data }) {
    return axios.patch(`api/schools/custom-navbar-menu/${id}/`, data);
  },

  async updateCustomNavbarItemOrder(_obj, data) {
    return axios.patch(`api/schools/custom-navbar-menu/update-navbar-order/`, data);
  },

  async deleteCustomNavbarItem(_obj, id) {
    return axios.delete(`api/schools/custom-navbar-menu/${id}/`);
  },

  async getSchoolCosumedTokens(_obj, params) {
    const res = await axios.get(`api/contact_program_info/school-consumed-tokens/`, { params });
    return res.data;
  },

  async getSchoolInstructorId(_obj, params) {
    const res = await axios.get(`api/schools/instructor-id/`, { params });
    return res.data;
  },

  async getSchoolByInstructorId(_obj, params) {
    const res = await axios.get(`api/schools/instructor-school-info/`, { params });
    return res.data;
  },

  async updateSchoolsEmpFilters({ commit }, { filters }) {
    commit('updateSchoolsEmpFilters', filters);
  },

  async reqAffiliation(_obj, data) {
    return axios.post(`api/affilates/`, data);
  },

  async getAllNationalSchools(_obj, params) {
    const res = await axios.get(`api/schools/national-partner/`, { params });
    return res.data;
  },

  async getNationalSchool(_obj, id) {
    const res = await axios.get(`api/schools/national-partner/${id}/`);
    return res.data;
  },

  async getMyAffiliations(_obj, params) {
    const res = await axios.get(`api/affilates/my-affiliations/`, { params });
    return res.data;
  },

  async showAffiliatedPrg(_obj, data) {
    return axios.post(`api/schools/national-partner/add-prg-to-directory/`, data);
  },

  async hideAffiliatedPrg(_obj, data) {
    return axios.delete(`api/schools/national-partner/rmv-prg-from-directory/`, { data });
  },

  async respondAffiliationReq(_obj, { id, data }) {
    return axios.patch(`api/affilates/${id}/respond-request/`, data);
  },

  async programStatus(_obj, data) {
    return axios.post(`api/schools/national-partner/prg-directory-status/`, data);
  },

  async partnerStatus(_obj, data) {
    return axios.post(`api/schools/national-partner/get-affiliation-status/`, data);
  },

  async deleteAffiliationReq(_obj, id) {
    return axios.delete(`api/affilates/${id}/`);
  },
};

export const school = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
