import Vue from 'vue';
import VueRouter from 'vue-router';

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch(err => err);
};

Vue.use(VueRouter);

import { routes } from './routes';
import { authenticate } from '../guards/authGuard';

import scrollBehavior from './scrollBehavior';

export const createRouter = () => {
  const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    linkActiveClass: 'active',
    linkExactActiveClass: 'active',
    routes,
    scrollBehavior,
  });

  // maintain affid query param in all routes for national partners affiliate referrals tracking
  router.beforeEach((to, from, next) => {
    if (from.query.affid && !to.query.affid) {
      next({
        ...to,
        query: { ...to.query, affid: from.query.affid },
      });
    } else {
      next();
    }
  });

  router.beforeEach((to, from, next) => {
    authenticate(to, from, next);
  });

  return router;
};

const router = createRouter();
export default router;
