import Vue from 'vue';

import {
  LayoutPlugin,
  ModalPlugin,
  CardPlugin,
  DropdownPlugin,
  TablePlugin,
  ToastPlugin,
  FormRadioPlugin
} from 'bootstrap-vue';

Vue.use(LayoutPlugin);
Vue.use(ModalPlugin);
Vue.use(CardPlugin);
Vue.use(DropdownPlugin);
Vue.use(TablePlugin);
Vue.use(ToastPlugin);
Vue.use(FormRadioPlugin);

import {
  BNavbar,
  BNavbarNav,
  BNavItemDropdown,
  BLink,
  BNavbarBrand,
  BBadge,
  BImg,
  BListGroup,
  BListGroupItem,
  BButton,
  BPagination,
  BAvatar,
  BCollapse,
  BBreadcrumb,
  BSkeletonWrapper,
  BSkeleton,
  BForm,
  BFormInput,
  BFormCheckbox,
  BFormGroup,
  BMedia,
  BFormFile,
  BFormInvalidFeedback,
  BAlert,
  BFormSelect,
  BSkeletonImg,
  BFormTextarea,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupText,
  BSkeletonTable,
  BTabs,
  BTab,
  BFormText,
  BSpinner,
  BPopover
} from 'bootstrap-vue';

Vue.component('BNavbar', BNavbar);
Vue.component('BNavbarNav', BNavbarNav);
Vue.component('BNavItemDropdown', BNavItemDropdown);
Vue.component('BLink', BLink);
Vue.component('BNavbarBrand', BNavbarBrand);
Vue.component('BBadge', BBadge);
Vue.component('BImg', BImg);
Vue.component('BListGroup', BListGroup);
Vue.component('BListGroupItem', BListGroupItem);
Vue.component('BButton', BButton);
Vue.component('BBtn', BButton);
Vue.component('BPagination', BPagination);
Vue.component('BAvatar', BAvatar);
Vue.component('BCollapse', BCollapse);
Vue.component('BBreadcrumb', BBreadcrumb);
Vue.component('BSkeletonWrapper', BSkeletonWrapper);
Vue.component('BSkeleton', BSkeleton);
Vue.component('BForm', BForm);
Vue.component('BFormInput', BFormInput);
Vue.component('BFormCheckbox', BFormCheckbox);
Vue.component('BFormGroup', BFormGroup);
Vue.component('BMedia', BMedia);
Vue.component('BFormFile', BFormFile);
Vue.component('BFormInvalidFeedback', BFormInvalidFeedback);
Vue.component('BAlert', BAlert);
Vue.component('BFormSelect', BFormSelect);
Vue.component('BSkeletonImg', BSkeletonImg);
Vue.component('BFormTextarea', BFormTextarea);
Vue.component('BInputGroup', BInputGroup);
Vue.component('BInputGroupPrepend', BInputGroupPrepend);
Vue.component('BInputGroupText', BInputGroupText);
Vue.component('BPopover', BPopover);

Vue.component('BSkeletonTable', BSkeletonTable);
Vue.component('BTabs', BTabs);
Vue.component('BTab', BTab);
Vue.component('BFormText', BFormText);
Vue.component('BSpinner', BSpinner);

import { VBPopover, VBToggle } from 'bootstrap-vue';

Vue.directive('b-popover', VBPopover);
Vue.directive('b-toggle', VBToggle);

import 'bootstrap-vue/dist/bootstrap-vue.css';

import {
  BIcon,
  BIconFacebook,
  BIconInstagram,
  BIconTwitter,
  BIconYoutube,
  BIconLinkedin,
  BIconMap
} from 'bootstrap-vue';

Vue.component('BIcon', BIcon);
Vue.component('BIconFacebook', BIconFacebook);
Vue.component('BIconInstagram', BIconInstagram);
Vue.component('BIconTwitter', BIconTwitter);
Vue.component('BIconYoutube', BIconYoutube);
Vue.component('BIconLinkedin', BIconLinkedin);
Vue.component('BIconMap', BIconMap);
