import axios from 'axios';
import { get } from 'lodash';

const state = {
  dark: {
    type: 'dark-pickled-bluewood',
    variant: 'navbar-shadow'
  },
  light: {
    type: 'light',
    variant: 'white border-bottom-2'
  },
  navbarBrandLogo: null,
  navbarBrandText: 'Transition Enroll',
  linkedEntity: null
};

const getters = {
  getNavbarBrandLogo: state => state.navbarBrandLogo,
  getNavbarBrandText: state => state.navbarBrandText,
  getLinkedEntity: state => state.linkedEntity
};

const mutations = {
  updateNavbarBrandDetails: (state, { logo, brandText }) => {
    state.navbarBrandLogo = logo;
    state.navbarBrandText = brandText;
  },
  updateLinkedEntity: (state, entity) => {
    state.linkedEntity = entity;
  }
};

const actions = {
  async updateNavbarBrandDetails({ commit }, linkedEntity = null) {
    if (!linkedEntity) {
      const resp = await axios.get('api/users/my-linked-entity/');
      linkedEntity = get(resp.data, 'data.linked_entity');
    }

    if (linkedEntity) {
      commit('updateNavbarBrandDetails', {
        logo: linkedEntity.logo_url,
        brandText: linkedEntity.name
      });
      commit('updateLinkedEntity', linkedEntity);
    } else {
      commit('updateNavbarBrandDetails', {
        logo: null,
        brandText: 'Transition Enroll'
      });
      commit('updateLinkedEntity', null);
    }
  },

  resetNavbarBrandDetails({ commit }) {
    commit('updateNavbarBrandDetails', {
      logo: null,
      brandText: 'Transition Enroll'
    });
  }
};

export const navbarConfig = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
