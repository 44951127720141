import axios from 'axios';

const state = {};

const getters = {};

const mutations = {};

const actions = {
  async getAllClasses(_obj, params) {
    const res = await axios.get(`api/classes/`, { params });
    return res.data;
  },

  async getClass(_obj, id) {
    const res = await axios.get(`api/classes/${id}/`);
    return res.data;
  },

  async createClass(_obj, data) {
    return axios.post(`api/classes/`, data);
  },

  async updateClass(_obj, { id, data }) {
    return axios.patch(`api/classes/${id}/`, data);
  },

  async deleteClass(_obj, id) {
    return axios.delete(`api/classes/${id}/`);
  },
  async updateStatus(_obj, { id, data }) {
    return axios.patch(`api/classes/${id}/update-status/`, data);
  },
  async getPublicClass(_obj, params) {
    const res = await axios.get(`api/classes/public-class-info/`, { params });
    return res.data;
  },
  async getRegistrationCounts(_obj, data) {
    return (await axios.post(`api/classes/get-registration-counts/`, data)).data.data;
  },

  async getClassRegistration(_obj, { id, params }) {
    const res = await axios.get(`api/classes/${id}/registrations/`, { params });
    return res.data;
  },

  async getSchoolClassesAggregate(_obj, params) {
    const res = await axios.get(`api/classes/school-aggregate/`, { params });
    return res.data;
  },

  async createDuplicateClass(_obj, data) {
    const res = await axios.post(`api/classes/duplicate-class/`, data);
    return res.data;
  },

  async getStudentRosters(_obj, params) {
    const res = await axios.get(`api/classes/export-student-rosters-csv/`, { params });
    return res.data;
  }
};

export const classes = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
