import axios from 'axios';
import { DEFAULT_FAVICON } from '../../common/constants';
import { get } from 'lodash';

const state = {
  accessToken: '',
  refreshToken: '',
  user: null,
  status: null,
  resetPasswordTokenInvalid: null,
  firstLoad: true
};

const getters = {
  isLoggedIn: state => {
    return !!state.accessToken && !!state.user;
  },
  getAuthToken: state => state.accessToken,
  getRefreshToken: state => state.refreshToken,
  getLoggedInUser: state => state.user,
  getResetPassTokenInvalid: state => state.resetPasswordTokenInvalid,
  getFirstLoad: state => state.firstLoad
};

const mutations = {
  login: (state, res) => {
    state.accessToken = res.accessToken;
    state.refreshToken = res.refreshToken;
    state.user = res.user;
  },
  logout: state => {
    state.accessToken = '';
    state.refreshToken = '';
    state.user = null;
  },
  updateMyProfile: (state, updatedUser) => {
    state.user = { ...state.user, ...updatedUser };
  },
  updatePassResetTokenInvalid: (state, res) => {
    state.resetPasswordTokenInvalid = res;
  },
  updateFirstLoad: state => {
    state.firstLoad = false;
  },
  updateLoggedInUserEntity: (state, linkedEntity) => {
    if (state.user) {
      state.user['linked_entity'] = { ...state.user.linked_entity, ...linkedEntity };
    }
  }
};

const actions = {
  async login({ commit }, data) {
    const loginResp = (
      await axios.post('api/token/', {
        email: data.email,
        password: data.password
      })
    ).data;

    // Store in state.
    commit('login', {
      accessToken: loginResp.access,
      refreshToken: loginResp.refresh,
      user: loginResp.user
    });

    return loginResp;
  },

  async logout({ commit, dispatch, rootState }) {
    dispatch('navbarConfig/resetNavbarBrandDetails', {}, { root: true });
    // TODO:
    // commit('student/updateMyStudentProfile', null, { root: true });
    commit('logout');
    // this.$store.commit('school/updateSchoolPaymentPlan', null);

    // Reset app colors after logout.
    const currSchool = rootState.school.currentSchool;
    document.documentElement.style.setProperty('--primary-color', get(currSchool, 'primary_color', '#00b3e6'));
    document.documentElement.style.setProperty('--secondary-color', get(currSchool, 'secondary_color', '#868e96'));
    document.documentElement.style.setProperty('--dark-color', get(currSchool, 'dark_color', '#214e6f'));
    document.getElementById('favicon').href = get(currSchool, 'linked_entity.small_logo_url') || DEFAULT_FAVICON;
  },

  async getMe() {
    const res = await axios.get(`api/users/me/`);
    return res.data.data;
  },

  async updateMyProfile({ commit }, data) {
    await axios.patch(`api/users/update-profile/`, data);

    commit('updateMyProfile', data);
  },

  async updateMyPassword(_obj, data) {
    await axios.patch(`api/users/update-password/`, data);
  },

  async getUserAvatarPresignedUrl(_obj, data) {
    return (await axios.post(`api/users/avatar-upload-url/`, data)).data.data;
  },

  async resetPassword(_obj, data) {
    return axios.post(`api/password-reset/`, data);
  },

  async resetPasswordConfirm(_obj, data) {
    return axios.post(`api/password-reset/confirm/`, data);
  },

  async validateResetPasswordToken(_obj, data) {
    return axios.post(`api/password-reset/validate_token/`, data);
  },

  updateResetPasswordTokenInvalid({ commit }, tokenInvalid) {
    commit('updatePassResetTokenInvalid', tokenInvalid);
  },

  setFirstLoad({ commit }) {
    commit('updateFirstLoad');
  },

  updateLoggedInUserEntity({ commit }, linkedEntity) {
    commit('updateLoggedInUserEntity', linkedEntity);
  }
};

export const auth = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
