import axios from 'axios';

const state = {};

const getters = {};

const mutations = {};

const actions = {
  async getAllEmails(_obj, params) {
    const res = await axios.get(`api/email-configs/`, { params });
    return res.data;
  },

  async getEmail(_obj, id) {
    const res = await axios.get(`api/email-configs/${id}/`);
    return res.data;
  },

  async updateEmail(_obj, { id, data }) {
    return axios.patch(`api/email-configs/${id}/`, data);
  },
  async getEmailLogs(_obj, params) {
    const res = await axios.get(`api/email-configs/email-logs/`, { params });
    return res.data;
  }
};

export const emailConfig = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
