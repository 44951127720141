export const USER_ROLES = {
  SUPER_ADMIN: 'super_admin',
  SCHOOL_OWNER: 'school_owner',
  SCHOOL_ADMIN: 'school_admin',
  STUDENT: 'student'
};

export const USER_ROLE_TYPES = {
  SUPER_ADMIN: 'super_admin',
  SCHOOL: 'school',
  STUDENT: 'student'
};

export const LANG_ABBREVIATIONS = {
  EN: 'en'
};

export const DURATION_TYPES = {
  WEEKS: 'weeks',
  DAYS: 'days'
};

export const PASSWORD_VALIDATIONS = {
  PASSWORDS_NOT_MATCH: 'passwords_not_matched',
  PASSWORDS_MIN_LENGTH: 'password_less_than_min_length',
  PASSWORDS_ALL_NUMERIC: 'password_all_numbers',
  INCORRECT_CURR_PASS: 'incorrect_curr_pass'
};

export const PASSWORD_ALL_NUMERIC_REGEX = /^\d+$/;
export const CNA_LICENSE_NUM_REGEX = /^CN\d{10}$/;
export const PHONE_FORMAT_REGEX = /^\(\d{3}\) \d{3} - \d{4}$/;
export const SSN_FORMAT_REGEX = /^\d{3}-\d{2}-\d{4}$/;

export const DEFAULT_PAGE_SIZE = 14;

// App customization related constants (use if needed).
export const APP_DARK_COLOR = '#214e6f';
export const DEFAULT_FAVICON = '/favicon.ico';

export const FACILITY_TYPES = {
  adult_day_care: 'Adult day care',
  assisted_living: 'Assisted living',
  hospital: 'Hospital',
  hospice_care: 'Hospice care',
  home_care: 'Home care',
  longterm_care: 'Long-term care',
  other: 'Other'
};

export const NOTIFICATION_ICONS = {
  program_info_query: 'contact_mail',
  student_registration: 'school',
  pre_enrollment_created: 'person',
  app_feature_update: 'lightbulb_outline',
  public_instructor_creation: 'people_alt',
  refund_requested: 'payment',
  refund_approved: 'check_circle',
  refund_declined: 'block',
  export_analytics: 'file_download',
  partnership_requested: 'error_outline',
  partnership_accepted: 'check_circle_outline'
};
export const NOTIFICATION_ICONS_COLOR = {
  program_info_query: 'primary',
  student_registration: 'primary',
  pre_enrollment_created: 'primary',
  app_feature_update: 'primary',
  public_instructor_creation: 'primary',
  refund_requested: 'primary',
  refund_approved: 'success',
  refund_declined: 'danger',
  export_analytics: 'primary',
  partnership_requested: 'primary',
  partnership_accepted: 'success'
};

// TODO: Payments related constants (use if needed.)
export const PAYMENT_STATUSES = {
  PAYMENT_FAILURE: 'payment_failure'
};

export const CARD_ICONS = {
  visa: 'visa',
  paypal: 'paypal',
  mastercard: 'mastercard',
  jcb: 'jcb',
  discover: 'discover',
  diners: 'diners-club',
  'apple-pay': 'apple-pay',
  amex: 'amex',
  'amazon-pay': 'amazon-pay'
};

export const TRANSACTION_STATUSES = {
  PENDING: 'pending',
  SUCCEEDED: 'succeeded',
  FAILED: 'failed',
  MANUAL: 'manually_paid'
};

export const TRANSACTION_STATUSES_COLORS = {
  [TRANSACTION_STATUSES.PENDING]: 'black-70',
  [TRANSACTION_STATUSES.SUCCEEDED]: 'success',
  [TRANSACTION_STATUSES.FAILED]: 'danger'
};

export const REVIEW_STATUSES = {
  PENDING: 'pending',
  APPROVED: 'approved',
  REJECTED: 'rejected'
};

export const REFUND_STATUSES = {
  PENDING: 'pending',
  APPROVED: 'approved',
  DECLINED: 'declined',
  CANCELLED: 'cancelled'
};

export const REVIEW_STATUSES_COLORS = {
  [REVIEW_STATUSES.PENDING]: 'black-70',
  [REVIEW_STATUSES.APPROVED]: 'success',
  [REVIEW_STATUSES.REJECTED]: 'danger'
};
// General
export const GENDERS = {
  MALE: 'male',
  FEMALE: 'female',
  OTHER: 'other'
};

export const GENDER_OPTIONS = [
  { value: 'male', text: 'Male' },
  { value: 'female', text: 'Female' },
  { value: 'other', text: 'Other' }
];

export const FORGOT_PASS_TOAST_OPTIONS = {
  INVALID_TOKEN: 'token_invalid',
  RESET_SUCCESSFUL: 'reset_successful'
};

export const PROGRAM_STATUSES = {
  OFFERING: 'offering',
  NOT_OFFERED: 'not_offered'
};

export const PROGRAM_TYPE = {
  ONSITE: 'onsite',
  BLENDED: 'blended',
  VIRTUAL: 'virtual'
};

export const CLASS_STATUSES = {
  REGISTRATION_OPEN: 'registration_open',
  REGISTRATION_CLOSED: 'registration_closed'
};

export const CLASS_STATUS_OPTIONS = [
  { value: null, text: 'Select Status' },
  { value: 'registration_open', text: 'Open' },
  { value: 'registration_closed', text: 'Closed' }
];

export const SELECT_SCHEDULE_TYPE_OPTIONS = [
  { value: null, text: 'Select Schedule' },
  { value: 'daytime', text: 'Daytime' },
  { value: 'evening', text: 'Evening' },
  { value: 'weekend', text: 'Weekend' }
];

export const CLASS_TYPES = {
  UPCOMING: 'upcoming',
  PREVIOUS: 'previous'
};

export const ETHNICITY_OPTIONS = [
  { value: 'hispanic', text: 'Hispanic' },
  { value: 'black_non_hispanic_black', text: 'Black / Non-Hispanic Black' },
  { value: 'white_non_hispanic', text: 'White / Non-Hispanic' },
  { value: 'american_indian_alaskan_native', text: 'American Indian / Alaskan Native' },
  { value: 'asian', text: 'Asian' },
  { value: 'middle_eastern', text: 'Middle Eastern' },
  { value: 'hawaiian_native_pacific_islander', text: 'Hawaiian Native / Pacific Islander' },
  { value: 'multiracial', text: 'Multiracial' },

  { value: 'other', text: 'Other' },
  { value: 'choose_not_to_report', text: 'Choose Not To Report' }
];

export const STD_PLAN_TO_PAY_OPTIONS = [
  {
    value: 'self_pay',
    text: 'Self Pay',
    description: `I will be paying the tuition myself.`,
    icon: 'fa-money-check-alt'
  },
  {
    value: 'sponsored_employer_agency',
    text: 'Sponsored',
    description: 'A sponsor will be paying the tuition (e.g. Employer, Agency).',
    icon: 'fa-building'
  },
  {
    value: 'not_sure_yet',
    text: 'Loan',
    description: 'A finance company will be paying the tuition.',
    icon: 'fa-university'
  }
];

export const STD_PLAN_TO_PAY = {
  SELF_PAY: 'self_pay',
  SPONSORED_EMPLOYER_AGENCY: 'sponsored_employer_agency',
  NOT_SURE_YET: 'not_sure_yet'
};

export const STD_REG_REASON_OPTIONS = [
  { text: 'To Work', value: 'to work' },
  { text: 'To Care for a Family Member', value: 'to care for a family member' },
  { text: 'As a Pre-requisite', value: 'as a prerequisite' },
  { text: 'Other', value: 'other' }
];

export const SIMULATION_VOICES_OPTIONS = [
  {
    value: 'openai-Nova',
    name: 'Nova',
    text: 'Female',
    image: 'nova.png',
    voice: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/nova_.wav'
  },
  {
    value: 'openai-Alloy',
    name: 'Alloy',
    text: 'Male',
    image: 'alloy.png',
    voice: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/alloy_.wav'
  },
  {
    value: 'openai-Echo',
    name: 'Echo',
    text: 'Male',
    image: 'echo.png',
    voice: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/echo_.wav'
  },
  {
    value: 'openai-Fable',
    name: 'Fable',
    text: 'Male',
    image: 'fable.png',
    voice: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/fable_.wav'
  },
  {
    value: 'openai-Onyx',
    name: 'Onyx',
    text: 'Male',
    image: 'onyx.png',
    voice: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/onyx_.wav'
  },
  {
    value: 'openai-Shimmer',
    name: 'Shimmer',
    text: 'Female',
    image: 'shimmer.png',
    voice: 'https://retell-utils-public.s3.us-west-2.amazonaws.com/shimmer_.wav'
  }
];

export const YES_NO_OPTIONS = [
  { value: true, text: 'Yes' },
  { value: false, text: 'No' }
];

export const SCHOOL_PAYMENT_PLANS = {
  BASIC: 'Basic',
  BASIC_AND_CHAT: 'Basic_chat',
  ADVANCED: 'Advanced',
  ADVANCED_AND_CHAT: 'Advanced_chat'
};

export const STD_REG_REFERRAL_OPTIONS = [
  { text: 'Referral', value: 'referral' },
  { text: 'Social Media', value: 'social media' },
  { text: 'Google', value: 'google' },
  { text: 'University', value: 'university' },
  { text: 'Billboard/Sign/Advertisement', value: 'billboard/sign/advertisement' },
  { text: 'Other', value: 'other' }
];

export const QUES_TYPE = [
  { value: null, text: 'Please Select', disabled: true },
  { value: 'boolean', text: 'Boolean' },
  { value: 'short_text', text: 'Short Text' },
  { value: 'long_text', text: 'Long Text' },
  { value: 'single_select', text: 'Single Select' },
  { value: 'multi_select', text: 'Multi Select' },
  { value: 'file_type', text: 'File Type' }
];

export const STATIC_FIELD_TYPE = {
  REQUIRED: 'required',
  DISABLED: 'disabled',
  OPTIONAL: 'optional'
};
export const QUES_TYPES = {
  BOOLEAN: 'boolean',
  LONG_TEXT: 'long_text',
  SHORT_TEXT: 'short_text',
  SINGLE_SELECT: 'single_select',
  MULTI_SELECT: 'multi_select',
  FILE_TYPE: 'file_type'
};

export const PAYOUT_ACC_STATUS = {
  VERIFIED: 'verified',
  LINKED: 'linked',
  UNLINKED: 'unlinked'
};

export const STD_REG_FORM_PAY_OPTIONS = [
  { value: 'pay_now', text: 'Right Now' },
  { value: 'pay_later', text: 'Later' }
];

export const STD_REG_FORM_MANUALLY_PAY_OPTIONS = [
  { value: 'partial_paid', text: 'Partially Paid' },
  { value: 'complete_paid', text: 'Completely Paid' }
];

export const STD_INITIATE_REG_FORM_PAY_OPTIONS = [
  { value: 'pay_now', text: 'Right Now' },
  { value: 'pay_later', text: 'Later' },
  { value: 'free_plan', text: 'Free Plan' }
];

export const STD_REG_PAY_OPTIONS = {
  PAY_NOW: 'pay_now',
  PAY_LATER: 'pay_later',
  FREE_PLAN: 'free_plan'
};

export const STD_REG_PAY_STATUSES = {
  PARTIAL_PAID: 'partial_paid',
  COMPLETE_PAID: 'complete_paid',
  UNPAID: 'unpaid'
};

export const STD_PRE_ENROLL_STATUSES = {
  APPROVED: 'approved',
  DISAPPROVED: 'disapproved',
  PENDING: 'pending',
  REGISTERED: 'registered'
};

export const STD_PRE_ENROLL_STATUSES_COLORS = {
  [STD_PRE_ENROLL_STATUSES.PENDING]: 'black-70',
  [STD_PRE_ENROLL_STATUSES.APPROVED]: 'success',
  [STD_PRE_ENROLL_STATUSES.DISAPPROVED]: 'danger',
  [STD_PRE_ENROLL_STATUSES.REGISTERED]: 'primary'
};

export const STD_REG_MIN_SERVICE_FEE = 2.99;

export const SCHEDULE_TYPE_OPTIONS = [
  { value: 'daytime', text: 'Daytime' },
  { value: 'evening', text: 'Evening' },
  { value: 'weekend', text: 'Weekend' }
];

export const EMAIL_STATUSES = {
  ENABLED: 'enabled',
  DISABLED: 'disabled'
};

export const EMAIL_BOOKMARKS = {
  std_enroll_student_success: [
    { type: 'menuitem', text: 'Full Name', value: '{{full_name}}' },
    { type: 'menuitem', text: 'Program Title', value: '{{program_title}}' },
    { type: 'menuitem', text: 'Payment Information', value: '{{payment_text}}' },
    { type: 'menuitem', text: 'Orientation Date', value: '{{orientation_date}}' },
    { type: 'menuitem', text: 'Start Date', value: '{{start_date}}' },
    { type: 'menuitem', text: 'School Name', value: '{{school_name}}' },
    { type: 'menuitem', text: 'Paid Amount', value: '{{paid_amount}}' },
    { type: 'menuitem', text: 'Pending Amount', value: '{{pending_amount}}' },
    { type: 'menuitem', text: 'Additional Program Info', value: '{{additional_prog_info}}' }
  ],
  automated_prg_inquiry_resp: [
    { type: 'menuitem', text: 'Full Name', value: '{{full_name}}' },
    { type: 'menuitem', text: 'Program Title', value: '{{program_title}}' },
    { type: 'menuitem', text: 'Program Duration', value: '{{program_duration}}' },
    { type: 'menuitem', text: 'Program Duration Type', value: '{{prog_duration_type}}' },
    { type: 'menuitem', text: 'Next Class Start Date', value: '{{next_class_start_date}}' },
    { type: 'menuitem', text: 'Next Class Orientation Date', value: '{{next_class_orientation_date}}' },
    { type: 'menuitem', text: 'Tuition Fee', value: '{{tuition_fee}}' },
    { type: 'menuitem', text: 'Application Fee', value: '{{application_fee}}' },
    { type: 'menuitem', text: 'School Name', value: '{{school_name}}' },
    { type: 'menuitem', text: 'Additional Program Info', value: '{{additional_prog_info}}' }
  ],
  follow_up_for_program_inquiry: [
    { type: 'menuitem', text: 'Full Name', value: '{{full_name}}' },
    { type: 'menuitem', text: 'Program Title', value: '{{program_title}}' },
    { type: 'menuitem', text: 'Program Duration', value: '{{program_duration}}' },
    { type: 'menuitem', text: 'Program Duration Type', value: '{{prog_duration_type}}' },
    { type: 'menuitem', text: 'Tuition Fee', value: '{{tuition_fee}}' },
    { type: 'menuitem', text: 'Application Fee', value: '{{application_fee}}' },
    { type: 'menuitem', text: 'School Name', value: '{{school_name}}' },
    { type: 'menuitem', text: 'Next Class Start Date', value: '{{next_class_start_date}}' },
    { type: 'menuitem', text: 'Registration Link', value: '{{registration_button}}' },
    { type: 'menuitem', text: 'Additional Program Info', value: '{{additional_prog_info}}' }
  ],
  waitlist_join_confirmation: [
    { type: 'menuitem', text: 'Full Name', value: '{{full_name}}' },
    { type: 'menuitem', text: 'Program Title', value: '{{program_title}}' },
    { type: 'menuitem', text: 'Program Duration', value: '{{program_duration}}' },
    { type: 'menuitem', text: 'Program Duration Type', value: '{{prog_duration_type}}' },
    { type: 'menuitem', text: 'Tuition Fee', value: '{{tuition_fee}}' },
    { type: 'menuitem', text: 'Application Fee', value: '{{application_fee}}' },
    { type: 'menuitem', text: 'School Name', value: '{{school_name}}' },
    { type: 'menuitem', text: 'Additional Program Info', value: '{{additional_prog_info}}' }
  ],
  offered_class_info_waitlist: [
    { type: 'menuitem', text: 'Full Name', value: '{{full_name}}' },
    { type: 'menuitem', text: 'Program Title', value: '{{program_title}}' },
    { type: 'menuitem', text: 'Program Duration', value: '{{program_duration}}' },
    { type: 'menuitem', text: 'Program Duration Type', value: '{{prog_duration_type}}' },
    { type: 'menuitem', text: 'Next Class Start Date:', value: '{{next_class_start_date}}' },
    { type: 'menuitem', text: 'Next Class Orientation Date', value: '{{next_class_orientation_date}}' },
    { type: 'menuitem', text: 'Tuition Fee', value: '{{tuition_fee}}' },
    { type: 'menuitem', text: 'Application Fee', value: '{{application_fee}}' },
    { type: 'menuitem', text: 'School Name', value: '{{school_name}}' },
    { type: 'menuitem', text: 'Registration Link', value: '{{registration_button}}' },
    { type: 'menuitem', text: 'Additional Program Info', value: '{{additional_prog_info}}' }
  ],
  scholarship_inquiry_resp: [
    { type: 'menuitem', text: 'Full Name', value: '{{full_name}}' },
    { type: 'menuitem', text: 'Program Title', value: '{{program_title}}' },
    { type: 'menuitem', text: 'Program Duration', value: '{{program_duration}}' },
    { type: 'menuitem', text: 'Program Duration Type', value: '{{prog_duration_type}}' },
    { type: 'menuitem', text: 'Next Class Start Date', value: '{{next_class_start_date}}' },
    { type: 'menuitem', text: 'Next Class Orientation Date', value: '{{next_class_orientation_date}}' },
    { type: 'menuitem', text: 'Tuition Fee', value: '{{tuition_fee}}' },
    { type: 'menuitem', text: 'Application Fee', value: '{{application_fee}}' },
    { type: 'menuitem', text: 'School Name', value: '{{school_name}}' },
    { type: 'menuitem', text: 'Requested Sponsorships', value: '{{requested_scholarships}}' },
    { type: 'menuitem', text: 'Additional Program Info', value: '{{additional_prog_info}}' }
  ]
};

export const EMAIL_BOOKMARKS_DESCRIPTION = {
  std_enroll_student_success: [
    { text: 'Full Name', description: "Student's full name" },
    { text: 'Program Title', description: 'Title of registered program' },
    {
      text: 'Payment Information',
      description:
        'Complete payment information containing the paid and pending amount details. (Pending amount details will be included if the student registers with Pay Later option.)'
    },
    { text: 'Orientation Date', description: 'Orientation date of the registered class.' },
    { text: 'Start Date', description: 'Start date of the registered class.' },
    { text: 'School Name', description: `School's name.` },
    { text: 'Paid Amount', description: 'The amount paid by the student.' },
    {
      text: 'Pending Amount',
      description: `The amount yet to be paid by the student. This is going to be the pending tuition fee if the student opts for Pay Later option otherwise it'll be 0.`
    },
    {
      text: 'Additional Program Info',
      description: `Extra info for the program for students after registration.`
    }
  ],
  automated_prg_inquiry_resp: [
    { text: 'Full Name', description: "Inquirer's full name." },
    { text: 'Program Title', description: 'Title of inquired program.' },
    { text: 'Program Duration', description: 'Duration of inquired program.' },
    { text: 'Program Duration Type', description: 'Duration type for offered program (Weeks/Days).' },
    { text: 'Next Class Start Date', description: 'Start date of the next class of inquired program.' },
    { text: 'Next Class Orientation Date', description: 'Orientation date of the next class of inquired program.' },
    { text: 'Tuition Fee', description: 'Tuition fee of inquired program.' },
    { text: 'Application Fee', description: 'Application fee of inquired program.' },
    { text: 'School Name', description: `School's name.` },
    {
      text: 'Additional Program Info',
      description: `Extra info for the program for students after registration.`
    }
  ],
  follow_up_for_program_inquiry: [
    { text: 'Full Name', description: "Inquirer's full name." },
    { text: 'Program Title', description: 'Title of inquired program.' },
    { text: 'Program Duration', description: 'Duration of inquired program.' },
    { text: 'Program Duration Type', description: 'Duration type for offered program (Weeks/Days).' },
    { text: 'Tuition Fee', description: 'Tuition fee of inquired program.' },
    { text: 'Application Fee', description: 'Application fee of inquired program.' },
    { text: 'School Name', description: `School's name.` },
    { text: 'Next Class Start Date', description: 'Start date of the next class of inquired program.' },
    { text: 'Registration Link', description: `Link to register in the offered class.` },
    {
      text: 'Additional Program Info',
      description: `Extra info for the program for students after registration.`
    }
  ],
  waitlist_join_confirmation: [
    { text: 'Full Name', description: "Inquirer's full name." },
    { text: 'Program Title', description: 'Title of inquired program.' },
    { text: 'Program Duration', description: 'Duration of inquired program.' },
    { text: 'Program Duration Type', description: 'Duration type for offered program (Weeks/Days).' },
    { text: 'Tuition Fee', description: 'Tuition fee of inquired program.' },
    { text: 'Application Fee', description: 'Application fee of inquired program.' },
    { text: 'School Name', description: `School's name.` },
    {
      text: 'Additional Program Info',
      description: `Extra info for the program for students after registration.`
    }
  ],
  offered_class_info_waitlist: [
    { text: 'Full Name', description: "Inquirer's full name." },
    { text: 'Program Title', description: 'Title of offered program.' },
    { text: 'Program Duration', description: 'Duration of offered program.' },
    { text: 'Program Duration Type', description: 'Duration type for offered program (Weeks/Days).' },
    { text: 'Next Class Start Date', description: 'Start date of the next class of offered program.' },
    { text: 'Next Class Orientation Date', description: 'Orientation date of the next class of offered program.' },
    { text: 'Tuition Fee', description: 'Tuition fee of offered program.' },
    { text: 'Application Fee', description: 'Application fee of offered program.' },
    { text: 'School Name', description: `School's name.` },
    { text: 'Registration Link', description: `Link to register in the offered class.` },
    {
      text: 'Additional Program Info',
      description: `Extra info for the program for students after registration.`
    }
  ],
  scholarship_inquiry_resp: [
    { text: 'Full Name', description: "Inquirer's full name." },
    { text: 'Program Title', description: 'Title of inquired program.' },
    { text: 'Program Duration', description: 'Duration of inquired program.' },
    { text: 'Program Duration Type', description: 'Duration type for offered program (Weeks/Days).' },
    { text: 'Next Class Start Date', description: 'Start date of the next class of inquired program.' },
    { text: 'Next Class Orientation Date', description: 'Orientation date of the next class of inquired program.' },
    { text: 'Tuition Fee', description: 'Tuition fee of inquired program.' },
    { text: 'Application Fee', description: 'Application fee of inquired program.' },
    { text: 'School Name', description: `School's name.` },
    { text: 'Requested Sponsorships', description: 'Links of requested sponsorships (if any).' },
    {
      text: 'Additional Program Info',
      description: `Extra info for the program for students after registration.`
    }
  ]
};

export const EMAIL_LOG_STATUSES = {
  PENDING: 'pending',
  SENT: 'sent',
  FAILED: 'failed'
};

export const EMAIL_LOG_STATUSES_COLORS = {
  [EMAIL_LOG_STATUSES.PENDING]: 'black-70',
  [EMAIL_LOG_STATUSES.SENT]: 'success',
  [EMAIL_LOG_STATUSES.FAILED]: 'danger'
};

export const WEEK_DAYS_OPTIONS = [
  { text: 'Sunday', value: '0' },
  { text: 'Monday', value: '1' },
  { text: 'Tuesday', value: '2' },
  { text: 'Wednesday', value: '3' },
  { text: 'Thursday', value: '4' },
  { text: 'Friday', value: '5' },
  { text: 'Saturday', value: '6' }
];

export const EMAIL_TYPES = {
  MANUAL: 'manual',
  AUTOMATED: 'automated',
  RECURRING: 'recurring',
  SYSTEM: 'system_generated',
  CONFIGURE: 'configurable'
};
export const PROGRAM_PAYMENT_OPTIONS = {
  TUITION_REQ: 'tuition_req',
  PAY_LATER: 'pay_later',
  PAY_LATER_NOW: 'pay_later_now'
};
export const PAYMENT_TYPE_OPTIONS = [
  { text: 'Select Type', value: null },
  { text: 'Percentage', value: 'percentage' },
  { text: 'Dollar Amount', value: 'dollar' }
];

export const PAYMENT_TYPE = {
  PERCENTAGE: 'percentage',
  DOLLAR_AMOUNT: 'dollar'
};

export const STATE_STATUS = {
  ACTIVE: 'active',
  INACTIVE: 'inactive'
};

export const STATE_STATUS_COLORS = {
  [STATE_STATUS.ACTIVE]: 'success',
  [STATE_STATUS.INACTIVE]: 'danger'
};

export const HOMEPAGE_ATTRIB_TYPE = {
  STATS: 'stats',
  FEATURES: 'feature'
};

export const SPONSORSHIP_SUBMISSION_TYPES = {
  IN_APP_SUBMISSION: 'in_app_submission',
  EXTERNAL_SUBMISSION: 'external_submission'
};

export const AI_BOOKMARKS = {
  prompt: [
    { type: 'menuitem', text: 'Title', value: '{{title}}' },
    { type: 'menuitem', text: 'Description', value: '{{description}}' },
    { type: 'menuitem', text: 'School Name', value: '{{school_name}}' },
    { type: 'menuitem', text: 'Program Fee', value: '{{program_fee}}' },
    { type: 'menuitem', text: 'Upcoming Class', value: '{{upcoming_class}}' }
  ]
};

export const AI_BOOKMARKS_DESCRIPTION = {
  prompt: [
    { text: '{{title}}', description: "Program's title" },
    { text: '{{description}}', description: 'Overview of the program' },
    { text: '{{school_name}}', description: 'Name of school of request initiator' },
    { text: '{{program_fee}}', description: "Program's fee" },
    { text: '{{upcoming_class}}', description: 'Upcoming class schedule' }
  ]
};

export const SPONSORSHIP_CATEGORIES_TYPES = {
  EMPLOYMENT: 'employment',
  GRANT: 'grant',
  LOAN: 'loan'
};

export const CLASS_SCHEDULE_TYPES = {
  SCHEDULED: 'scheduled',
  SELF_PACED: 'self_paced'
};

export const REGISTRATION_TYPES = {
  CANCELLED: 'cancelled',
  ACTIVE: 'active'
};

export const STUDENT_PAYMENT_TYPES = {
  PAID: 'paid',
  CANCELLED: 'cancelled',
  UNPAID: 'unpaid'
};

export const SYSTEM_EMAILS = [
  {
    id: 1,
    title: 'Product Purchase Invoice (for Students)',
    body: `
    <div style="text-align:center; margin-top: 20px">
    <h1
    style="
      color: #1e1e2d;
      font-weight: 500;
      margin: 0;
      font-size: 32px;
      font-family: 'Rubik', sans-serif;
    "
  >
    Product Purchase Invoice
  </h1>
  <span
  style="
  display: inline-block;
  margin: 29px 0 26px;
  border-bottom: 1px solid #cecece;
  width: 100px;
  "
  ></span>
  </div>
  
    Hey {{full_name}},
    <br>
    <br>
    It it to inform you that \${{total_prd}} has been
    deducted from your account for purchasing school
    products. Please find the following invoice
    <b>#{{invoice_num}}</b> for your purchase order.
  </p>
      `,
    email_abbr: 'product_purchase_invoice_for_student',
    subject: 'Product Purchase Invoice',
    type: 'automated',
    items: [
      { Name: '{{product_title }}', Price: '${{ unit_price }}', Quantity: '{{ quantity }}', Amount: '${{ amount }}' },
      { Name: 'Total', Price: '', Quantity: '', Amount: '${{total_prd}}' }
    ],
    afterTable: `
    <p>
    If you have any issue, please contact the administrator.
  </p>
  <p>
    Regards,
    <br>
    {{school_name}} Team
  </p>

  <div
  style="
    font-size: 14px;
    color: rgba(69, 80, 86, 0.7411764705882353);
    line-height: 18px;
    margin-top: 30px;

    text-align: center;
  "
>
  &copy; {{ year }}
  <a
    href="https://healthcareenroll.io/"
    style="text-decoration: none; color: #214e6f !important"
    target="_blank"
    ><strong>Transition Enroll</strong></a
  >
</div>
    `
  },
  {
    id: 2,
    title: 'Payment Plan (for Students)',
    body: `
    <div style="text-align:center; margin-top: 20px">
    <h1
    style="
      color: #1e1e2d;
      font-weight: 500;
      margin: 0;
      font-size: 32px;
      font-family: 'Rubik', sans-serif;
    "
  >
  Payment Plan for Pending Dues
  </h1>
  <span
  style="
  display: inline-block;
  margin: 29px 0 26px;
  border-bottom: 1px solid #cecece;
  width: 100px;
  "
  ></span>
  </div>
    
    
    <p

  >
    Hey {{ full_name }},
  </p>
  <p

  >
    Please find the following payment plan for your pending
    dues for {{program_title}} registration.
  </p>
      `,
    email_abbr: '',
    subject: 'Payment Plan for Pending Dues',
    type: 'automated',
    items: [{ Due_Date: '{{ date }}', Tuition: '${{ tuition_fee }} ({{ percentage }}%)' }],
    afterTable: `
    <p>Kindly use the link given below to pay your pending dues.If you have any issue, please contact the administrator.
     </p>
     <div style="text-align: center">
      <a style="
      background: #214e6f;
      text-decoration: none !important;
      font-weight: 500;

      color: #fff;
      font-size: 14px;
      padding: 10px 24px;
      display: inline-block;
      border-radius: 4px;
    ">Payment Plans</a> 
</div>
      <p>Regards,<br/>
      {{school_name}} Team
      </p>

      <div
      style="
        font-size: 14px;
        color: rgba(69, 80, 86, 0.7411764705882353);
        line-height: 18px;
        margin-top: 30px;

        text-align: center;
      "
    >
      &copy; {{ year }}
      <a
        href="https://healthcareenroll.io/"
        style="text-decoration: none; color: #214e6f !important"
        target="_blank"
        ><strong>Transition Enroll</strong></a
      >
    </div>
    `
  },
  {
    id: 3,
    title: 'Chat Transcript',
    body: `
    <div style="text-align:center; margin-top: 20px">
    <h1
    style="
      color: #1e1e2d;
      font-weight: 500;
      margin: 0;
      font-size: 32px;
      font-family: 'Rubik', sans-serif;
    "
  >
  Your Chat Transcript
  </h1>
  <span
  style="
  display: inline-block;
  margin: 29px 0 26px;
  border-bottom: 1px solid #cecece;
  width: 100px;
  "
  ></span>
  </div>

    Hey {{full_name}},<br />
                        you recently inquired about {{program_title}}.
                        Here is your chat transcript.
                      </p>
                      <h4 style="text-align: center;">Transcript</h4>
 

                          <div style="display:flex; margin-bottom: 8px; padding: 15px" class="wrapper">
                          <div style="margin-right:10px;"  class="profile"  >
                          <img src="https://transition-prod.s3.amazonaws.com/resources/user.png" alt="human" style="background-color:{{secondary_color}};">
                          </div>
                          <div style="margin-top: 12px">
                          {{ Sample User Message }} 
                          
                          </div>
                          
                          </div>
                          
                          <div  style="display:flex; margin-bottom: 15px; padding: 15px" class="wrapper ai">
                          <div style="margin-right:10px" class="profile">
                          <img src="https://transition-prod.s3.amazonaws.com/resources/bot.png" alt="bot" style="background-color:{{primary_color}};" >
                            </div>
                            <div style="margin-top: 12px">
                               {{ Sample Bot Message }}
                            </div>                                
                          </div>
                  
          
                       <p
          
                      >
                        If you have any issue, please contact the administrator.
                      </p>
                      <p
                
                      >
                        Regards,<br />
                        {{school_name}} Team
                      </p>

                      <div
                      style="
                        font-size: 14px;
                        color: rgba(69, 80, 86, 0.7411764705882353);
                        line-height: 18px;
                        margin-top: 30px;

                        text-align: center;
                      "
                    >
                      &copy; {{ year }}
                      <a
                        href="https://healthcareenroll.io/"
                        style="text-decoration: none; color: #214e6f !important"
                        target="_blank"
                        ><strong>Transition Enroll</strong></a
                      >
                    </div>
    `,
    email_abbr: 'close_chats',
    subject: 'Your Chat Transcript',
    type: 'automated'
  },
  {
    id: 4,
    title: 'Reminder for Payment Dues',
    body: `
    <div style="text-align:center; margin-top: 20px">
    <h1
    style="
      color: #1e1e2d;
      font-weight: 500;
      margin: 0;
      font-size: 32px;
      font-family: 'Rubik', sans-serif;
    "
  >
  Your Payment is due
  </h1>
  <span
  style="
  display: inline-block;
  margin: 29px 0 26px;
  border-bottom: 1px solid #cecece;
  width: 100px;
  "
  ></span>
  </div>



    <p

  >
    Hey {{ full_name }},
  </p>
  <p

  >
    Your installment payment for {{program_name}} is due on {{date}}
    Kindly use the link given below to pay your pending dues at your earliest.
  </p>
  <div style="text-align: center">
  <a
  style="
  background: #214e6f;
  text-decoration: none !important;
  font-weight: 500;

  color: #fff;
  font-size: 14px;
  padding: 10px 24px;
  display: inline-block;
  border-radius: 4px;
"

    >Pay Here</a
  > 
</div>
  <p

  >
  
    If you have any issue, please contact the administrator.
  </p>
  <p

  >
    Regards,<br />
    {{school_name}} Team
  </p>

  <div
  style="
    font-size: 14px;
    color: rgba(69, 80, 86, 0.7411764705882353);
    line-height: 18px;
    margin-top: 30px;

    text-align: center;
  "
>
  &copy; {{ year }}
  <a
    href="https://healthcareenroll.io/"
    style="text-decoration: none; color: #214e6f !important"
    target="_blank"
    ><strong>Transition Enroll</strong></a
  >
</div>
    `,
    email_abbr: 'reminder_payment_dues',
    subject: 'Your Payment is due',
    type: 'recurring',
    time: '5 AM ET',
    day: 'Everyday'
  },

  {
    id: 5,
    title: 'Enrollment Request Approval',
    body: `
    <div style="text-align:center; margin-top: 20px">
    <h1
    style="
      color: #1e1e2d;
      font-weight: 500;
      margin: 0;
      font-size: 32px;
      font-family: 'Rubik', sans-serif;
    "
  >
  Your Enrollment request has been approved
  </h1>
  <span
  style="
  display: inline-block;
  margin: 29px 0 26px;
  border-bottom: 1px solid #cecece;
  width: 100px;
  "
  ></span>
  </div>

    <p

  >
    Hey {{ full_name }},
  </p>
  <p

  >
    Your enrollment request has been approved 
    for <b>{{program_title}}</b> in class <b>#{{class_id}}</b>.
    Click the link given below to register.
  </p>


  <p

  >
    If you have any issue, please contact the administrator.
  </p>
  <p

  >
    Regards,<br />
    {{school_name}} Team
  </p>
  <div style="text-align: center">
  <a
  style="
  background: #214e6f;
  text-decoration: none !important;
  font-weight: 500;

  color: #fff;
  font-size: 14px;
  padding: 10px 24px;
  display: inline-block;
  border-radius: 4px;
"
    >View Registration Details</a
  >
  </div>
   
  <div
  style="
    font-size: 14px;
    color: rgba(69, 80, 86, 0.7411764705882353);
    line-height: 18px;
    margin-top: 30px;

    text-align: center;
  "
>
  &copy; {{ year }}
  <a
    href="https://healthcareenroll.io/"
    style="text-decoration: none; color: #214e6f !important"
    target="_blank"
    ><strong>Transition Enroll</strong></a
  >
</div>
  `,
    email_abbr: 'pre_enrollment_approved',
    subject: 'Your Enrollment request has been approved',
    type: 'automated'
  },
  {
    id: 6,
    title: 'Registration Initiation Response',
    body: `
    <div style="text-align:center; margin-top: 20px">
    <h1
    style="
      color: #1e1e2d;
      font-weight: 500;
      margin: 0;
      font-size: 32px;
      font-family: 'Rubik', sans-serif;
    "
  >
  Your Registration has been Initiated
  </h1>
  <span
  style="
  display: inline-block;
  margin: 29px 0 26px;
  border-bottom: 1px solid #cecece;
  width: 100px;
  "
  ></span>
  </div>


    <p>
                        Hey {{ full_name }},
                      </p>
                      <p>
                        This is to inform you that your registration in
                        {{program_title}} has been initiated, click on the link given below to complete it.
                      </p>
    `,
    email_abbr: 'pre_enrollment_approved',
    subject: 'Your Registration has been Initiated',
    type: 'automated',
    afterTable: `
    <p
                       
                      >
                        If you have any issue, please contact the administrator.
                      </p>
                      <p
                     
                      >
                        Regards,<br />
                        {{school_name}} Team
                      </p>
                      
                      <div style="text-align: center">
                      <a
                      style="
                      background: #214e6f;
                      text-decoration: none !important;
                      font-weight: 500;
                    
                      color: #fff;
                      font-size: 14px;
                      padding: 10px 24px;
                      display: inline-block;
                      border-radius: 4px;
                    "
                        >Register</a
                      >
                      </div>

                      <div
                      style="
                        font-size: 14px;
                        color: rgba(69, 80, 86, 0.7411764705882353);
                        line-height: 18px;
                        margin-top: 30px;

                        text-align: center;
                      "
                    >
                      &copy; {{ year }}
                      <a
                        href="https://healthcareenroll.io/"
                        style="text-decoration: none; color: #214e6f !important"
                        target="_blank"
                        ><strong>Transition Enroll</strong></a
                      >
                    </div>
    `
  }
];
export const EMAIL_SUBSCRIPTION_STATUS = {
  DISABLED: 'disabled',
  ENABLED: 'enabled',
  INVALID_TOKEN: 'invalid_token'
};

export const PARTNERSHIP_STATUS = {
  REVOKED: 'revoked',
  ACCEPTED: 'accepted',
  REQUESTED: 'requested',
  NOT_REQUESTED: 'not_requested'
};

export const SCHOOL_TYPES = {
  LOCAL: 'local',
  NATIONAL: 'national_partner'
};

export const SCHOOL_DEF_CANCEL_POLICY = `<p><strong>Cancellation and Refund Policy</strong><br />Before proceeding with enrollment, please read and understand our Terms and Conditions.<br /><br /><strong>1. Acceptance of Terms</strong><br />By enrolling in any of our training programs, you agree to comply with and be legally bound by these terms and conditions.<br /><br /><strong>2. Non-refundable Fees</strong><br />The tuition paid for enrollment includes processing fees, which are collected by our payment provider, Stripe. Please note that these processing fees, as well as the initial deposit, are non-refundable, regardless of withdrawal status. This is due to the non-retrievable nature of these fees once paid to the payment provider.<br /><br /><strong>3. Program Withdrawal</strong><br />If you choose to withdraw from a program for reasons unrelated to our school, please understand that the processing fees and application fee will NOT be refunded. Attempting to dispute these charges with your bank will not result in a refund, as you have been clearly informed that these fees are non-refundable. Ensure you are fully committed to attending this training course before completing your application. If you’re unable to attend a specific class date after enrolling, please contact the school administration directly to transfer to another class start date without incurring an additional application fee.<br /><br />
<strong>4. Confirmation of Enrollment</strong><br />By signing this agreement, you confirm that you are the rightful consumer purchasing this course with the intention to attend and complete it.<br /><br /><strong>5. Questions or Concerns</strong><br />If you are unsure or have questions about this agreement, please contact the school administration before proceeding with your enrollment.<br /><br />By proceeding with enrollment, you acknowledge that you understand and agree to these terms. </p>`;