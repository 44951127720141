import axios from 'axios';

const state = {};

const getters = {};

const mutations = {};

const actions = {
  async getAllInstructors(_obj, params) {
    const res = await axios.get(`api/instructors/`, { params });
    return res.data;
  },

  async getInstructor(_obj, id) {
    const res = await axios.get(`api/instructors/ ${id}/`);
    return res.data;
  },

  async createInstructor(_obj, data) {
    return axios.post(`api/instructors/`, data);
  },

  async updateInstructor(_obj, { id, data }) {
    return axios.patch(`api/instructors/${id}/`, data);
  },
  async getInstructorImageUploadPresignedUrl(_obj, data) {
    return (await axios.post(`api/instructors/instructor-upload-url/`, data)).data.data;
  },

  async deleteClass(_obj, id) {
    return axios.delete(`api/instructors/${id}/`);
  },

  async deleteInstructor(_obj, id) {
    return axios.delete(`api/instructors/${id}/`);
  },

  async getPublicInstructors(_obj, params) {
    const res = await axios.get(`api/instructors/list-public-instructors/`, { params });
    return res.data;
  },

  async getSchoolInstructorsAggregate(_obj, params) {
    const res = await axios.get(`api/instructors/school-aggregate/`, { params });
    return res.data;
  },

  async signupInstructor(_obj, data) {
    return axios.post(`api/instructors/public-create-instructor/`, data);
  }
};

export const instructor = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
