import axios from 'axios';

const state = {};

const getters = {};

const mutations = {};

const actions = {
  async getAllLocations(_obj, params) {
    const res = await axios.get(`api/locations/`, { params });
    return res.data;
  },

  async getLocation(_obj, id) {
    const res = await axios.get(`api/locations/${id}/`);
    return res.data;
  },

  async getBestStateOptions(_obj, params) {
    const res = await axios.get(`api/locations/best-school-state/`, { params });
    return res.data;
  },

  async getBestCitiesOptions(_obj, data) {
    const res = await axios.post(`api/locations/best-school-state/best-states-cities/`, data);
    return res.data;
  },

  async createLocation(_obj, data) {
    return axios.post(`api/locations/`, data);
  },

  async updateLocation(_obj, { id, data }) {
    return axios.patch(`api/locations/${id}/`, data);
  },

  async deleteLocation(_obj, id) {
    return axios.delete(`api/locations/${id}/`);
  },
  async createBestSchoolState(_obj, data) {
    return axios.post(`api/locations/best-school-state/`, data);
  },
  async updateBestSchoolState(_obj, { id, data }) {
    return axios.patch(`api/locations/best-school-state/${id}/`, data);
  },
  async createBestSchoolCity(_obj, data) {
    return axios.post(`api/locations/best-school-city/`, data);
  },
  async updateBestSchoolCity(_obj, { id, data }) {
    return axios.patch(`api/locations/best-school-city/${id}/`, data);
  },
  async getAllStates(_obj, params) {
    const res = await axios.get(`api/locations/best-school-state/`, { params });
    return res.data;
  },
  async getAllCities(_obj, params) {
    const res = await axios.get(`api/locations/best-school-city/`, { params });
    return res.data;
  },
  async deleteState(_obj, id) {
    return axios.delete(`api/locations/best-school-state/${id}/`);
  },
  async deleteCity(_obj, id) {
    return axios.delete(`api/locations/best-school-city/${id}/`);
  },
  async getBestSchoolState(_obj, id) {
    const res = await axios.get(`api/locations/best-school-state/${id}/`);
    return res.data;
  },
  async getBestSchoolCity(_obj, id) {
    const res = await axios.get(`api/locations/best-school-city/${id}/`);
    return res.data;
  },
  async getStatePresignedUrl(_obj, data) {
    return (await axios.post(`api/locations/best-school-state/image-upload-url/`, data)).data.data;
  },
  async getCityPresignedUrl(_obj, data) {
    return (await axios.post(`api/locations/best-school-city/image-upload-url/`, data)).data.data;
  },
  async updateStateStatus(_obj, { id, data }) {
    return axios.patch(`api/locations/best-school-state/${id}/state-status-update/`, data);
  },
  async updateCityStatus(_obj, { id, data }) {
    return axios.patch(`api/locations/best-school-city/${id}/city-status-update/`, data);
  },
  async replaceDeleteLocation(_obj, data) {
    return (await axios.post(`api/locations/delete-replace/`, data)).data.data;
  }
};

export const location = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
